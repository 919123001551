import React, { useEffect, useMemo, useState } from "react";
import classNames from "./TaxFormDetailsComponent.module.scss";
import {
  Table,
  Input,
  Card,
  Typography,
  Col,
  Row,
  Select,
  Form,
  Tooltip,
  Tag,
} from "antd";
import { CTAButton, Message, Modal } from "../../../../common";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useMutation } from "react-query";
import api from "../../../../API";
import { ReactComponent as EditIcon } from "../../../../../assets/icons/edit-fill.svg";
import { ReactComponent as ResetIcon } from "../../../../../assets/icons/reset.svg";
import { ReactComponent as BackIcon } from "../../../../../assets/icons/arrow-back.svg";
import moment from "moment";

const TaxFormDetailsComponent = ({ profileData }) => {
  const { formId } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const [detailsForm] = Form.useForm();
  const [isInitialLoad, setIsInitalLoad] = useState(true);
  const [submitModalVisible, setSubmitModalVisible] = useState();
  const [successModalVisible, setSuccessModalVisible] = useState();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [editableFields, setEditableFields] = useState([]);
  const [taxQuestions, setTaxQuestions] = useState([]);
  const [taxForms, setTaxForms] = useState({
    form_data: {
      "Form Details": [],
      "Tax Calculations": [],
    },
  });
  // If you need loading state:
const [isLoading, setIsLoading] = useState(true);

useEffect(() => {
  if (taxForms?.form_data) {
    setIsLoading(false);
  }
}, [taxForms]);
  const [originalTaxForm, setOriginalTaxForm] = useState({
    form_data: {
      "Form Details": [],
      "Tax Calculations": [],
    },
  });

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };
  useEffect(() => {
    //Payer Details 
    const name = taxQuestions?.find?.((item) => item.key === "name");
    const streetAddress = taxQuestions?.find?.(
      (item) => item.key === "business_address"
    );
    const stateOfIncorporation = taxQuestions?.find?.(
      (item) => item.key === "state_of_incorporation"
    );
    const payersTIN = taxQuestions?.find?.((item) => item.key === "tin");
  
    // Define contractorInfo here, before it's used
    const contractorInfo = {
      "PAYER'S name, street address, city or town, state or province, country, ZIP or foreign postal code, and telephone no.": 
        `${taxForms.form_data["Form Details"].find(item => item.field === "PAYER'S name, street address, city or town, state or province, country, ZIP or foreign postal code, and telephone no.")?.current_value || ""}`,
      "PAYER'S TIN": 
        `${taxForms.form_data["Form Details"].find(item => item.field === "PAYER'S TIN")?.current_value || ""}`,
      "17 State/Payer's state no.": 
        `${taxForms.form_data["Form Details"].find(item => item.field === "17 State/Payer's state no.")?.current_value || ""}`,
      "RECIPIENT'S name": 
        `${taxForms.form_data["Form Details"].find(item => item.field === "RECIPIENT'S name")?.current_value || ""}`,
      "Street address (including apt. no.)": 
        `${taxForms.form_data["Form Details"].find(item => item.field === "Street address (including apt. no.)")?.current_value || ""}`,
      "Account number (see instructions)": 
        `${taxForms.form_data["Form Details"].find(item => item.field === "Account number (see instructions)")?.current_value || ""}`,
      "City or town, state or province, country, and ZIP or foreign postal code": 
        `${taxForms.form_data["Form Details"].find(item => item.field === "City or town, state or province, country, and ZIP or foreign postal code")?.current_value || ""}`
    };
  
    const filteredcontractoInfo = Object.fromEntries(
      Object.entries(contractorInfo).filter(([key, value]) => !!value)
    );
  
    const formData = Object.values(taxForms?.form_data || {})
      .flat()
      .filter((item) => {
        if (isInitialLoad && skippedFields.includes(item.field)) {
          return {};
        }
        return item;
      })
      .map((item) => ({ [item.field]: item?.current_value }))
      .reduce((acc, currentValue) => ({ ...acc, ...currentValue }), {});
  
    detailsForm.setFieldsValue({ ...formData, ...filteredcontractoInfo });
  
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [taxForms, isInitialLoad]);
  const getTaxForms = useMutation(
    ({ formId }) =>
      api.Services.AccountantsDashboard.Tax.getTaxFormDetails({
        formId,
        business_id: profileData?.business_id,
        contractor_id: location?.state?.contractor?.contractor_id
      }),
    {
      onSuccess: (response) => {
        setTaxForms(response?.data);
      },
      onError: () => {},
    }
  );

  const generatePDF = useMutation(
    () =>
      api.Services.AccountantsDashboard.Tax.generatePdf({
        business_id: profileData?.business_id,
        contractor_id: location?.state?.contractor?.contractor_id
      }),
    {
      onSuccess: (response) => {
        window.open(response?.data?.file_url);
      },
      onError: () => {},
    }
  );

  const submitPDF = useMutation(
    () =>
      api.Services.AccountantsDashboard.Tax.submitPdf({
        business_id: profileData?.business_id,
        contractor_id: location?.state?.contractor?.contractor_id
      }),
    {
      onSuccess: (response) => {
        Message({ type: "success", content: response.message });
        toggleSuccessModal()
      },
      onError: () => {
        Message({ type: "error", content: "Failed to submit form" })
        toggleSubmitModal()
      },
    }
  );

  const getTaxOriginalForms = useMutation(
    ({ formId }) =>
      api.Services.AccountantsDashboard.Tax.getTaxFormDetails({
        formId,
        business_id: profileData?.business_id,
        fetchOriginal: true,
        contractor_id: location?.state?.contractor?.contractor_id
      }),
    {
      onSuccess: (response) => {
        setOriginalTaxForm(response?.data);
      },
      onError: () => {},
    }
  );

  const updateTaxForms = useMutation(
    ({ formId, updates }) =>
      api.Services.AccountantsDashboard.Tax.updateTaxFormDetails({
        formId,
        updates,
        business_id: profileData?.business_id,
      }),
    {
      onSuccess: () => {
        setSelectedRowKeys([]);
        setEditableFields([]);
        Message({ type: "success", content: "Updated details successfully!" });
        getTaxForms.mutate({ formId });
      },
      onError: () => {},
    }
  );

  const currentValues = detailsForm.getFieldsValue();
  useEffect(() => {
    const contractor = location?.state?.contractor;
    
    if (taxQuestions.length && contractor && isInitialLoad) {
      const name = taxQuestions.find((item) => item.key === "name");
      const streetAddress = taxQuestions.find(
        (item) => item.key === "business_address"
      );
      const stateOfIncorporation = taxQuestions.find(
        (item) => item.key === "state_of_incorporation"
      );
      const payersTIN = taxQuestions.find((item) => item.key === "tin");

      detailsForm.setFieldsValue({
        "PAYER'S name, street address, city or town, state or province, country, ZIP or foreign postal code, and telephone no.": `${taxForms.form_data["Form Details"].find(item => item.field === "PAYER'S name, street address, city or town, state or province, country, ZIP or foreign postal code, and telephone no.")?.current_value}`,
        "PAYER'S TIN": `${taxForms.form_data["Form Details"].find(item => item.field === "PAYER'S TIN")?.current_value}`,
        "RECIPIENT'S name": `${taxForms.form_data["Form Details"].find(item => item.field === "RECIPIENT'S name")?.current_value}`,
        "17 State/Payer's state no.": `${taxForms.form_data["Form Details"].find(item => item.field === "17 State/Payer's state no.")?.current_value}`,
        "Street address (including apt. no.)": `${taxForms.form_data["Form Details"].find(item => item.field === "Street address (including apt. no.)")?.current_value}`,
        "Account number (see instructions)": `${
          taxForms.form_data["Form Details"].find(item=> item.field === "Account number (see instructions)") || ""
        }`,
        "City or town, state or province, country, and ZIP or foreign postal code": `${
          taxForms.form_data["Form Details"].find(item=> item.field === "City or town, state or province, country, and ZIP or foreign postal code") || ""
        }, ${
          taxForms.form_data["Form Details"].find(item=> item.field === "City or town, state or province, country, and ZIP or foreign postal code") || ""
        }, ${contractor?.contractor_billing_address?.PostalCode || ""}`,
      });
      setIsInitalLoad(false);
    }
  }, [
    currentValues,
    taxQuestions,
    detailsForm,
    isInitialLoad,
    location?.state?.contractor,
    taxForms.form_data,
  ]);

  const skippedFields = [
    "RECIPIENT'S name",
    "Street address (including apt. no.)",
    "Account number (see instructions)",
    "City or town, state or province, country, and ZIP or foreign postal code",
  ];

  const onPressDetailsSave = (isResetting) => {
    const formObject = detailsForm.getFieldsValue();

    const updatedValuesArray = Object.entries(formObject)?.filter?.(
      ([_, value]) => value !== undefined || value?.trim() !== ""
    );

    if (updatedValuesArray.length) {
      const updatedFields = Object.fromEntries(updatedValuesArray);
      const updates = taxForms?.form_data?.["Form Details"]?.map((item) => {
        if (
          (updatedFields[item.field] !== undefined &&
            editableFields.includes(item.field)) ||
          isResetting
        ) {
          return {
            ...item,
            current_value: formObject[item.field],
            status: "CONFIRMED",
          };
        }
        return item;
      });

      const calculationUpdates = taxForms?.form_data?.["Tax Calculations"]?.map(
        (item) => {
          if (
            updatedFields[item.field] !== undefined &&
            editableFields.includes(item.field)
          ) {
            return {
              ...item,
              current_value: formObject[item.field],
              status: "Confirmed",
            };
          }
          return item;
        }
      );

      const apiUpdates = {
        form_data: {
          form_data: {
            ...taxForms?.form_data,
            "Form Details": updates,
            "Tax Calculations": calculationUpdates,
          },
        },
      };
      updateTaxForms.mutate({ formId, updates: apiUpdates });
    }
  };

  const onPressConfirm = () => {
    if (selectedRowKeys.length) {
      const confirmedFieldsObject = selectedRowKeys.reduce((acc, value) => {
        return { ...acc, [value]: true };
      }, {});

      const updates = taxForms?.form_data?.["Form Details"]?.map((item) => {
        if (confirmedFieldsObject[item.field] !== undefined) {
          return { ...item, status: "CONFIRMED" };
        }
        return item;
      });

      const calculationupdates = taxForms?.form_data?.["Tax Calculations"]?.map(
        (item) => {
          if (confirmedFieldsObject[item.field]) {
            return { ...item, status: "CONFIRMED" };
          }
          return item;
        }
      );

      const apiUpdates = {
        ...taxForms,
        form_data: {
          form_data: {
            ...(taxForms?.form_data || {}),
            "Form Details": updates,
            "Tax Calculations": calculationupdates,
          },
        },
      };
      updateTaxForms.mutate({ formId, updates: apiUpdates });
    }
  };

  const getBusinessDataCollection = useMutation(() => {
    return api.Services.Dashboard.TaxFilling.getBusinessQuestion({
      business_id: profileData?.business_id,
    }).then((response) => {
      setTaxQuestions(response?.data?.questions);
    });
  });

  useEffect(() => {
    getTaxForms.mutate({ formId });
    getTaxOriginalForms.mutate({ formId });
    getBusinessDataCollection.mutate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formId, profileData?.business_id]);

  useEffect(() => {
    //Payer Details 

    const name = taxQuestions?.find?.((item) => item.key === "name");
    const streetAddress = taxQuestions?.find?.(
      (item) => item.key === "business_address"
    );
    const stateOfIncorporation = taxQuestions?.find?.(
      (item) => item.key === "state_of_incorporation"
    );
    const payersTIN = taxQuestions?.find?.((item) => item.key === "tin");



    //const filteredcontractoInfo = Object.fromEntries(
    //  Object.entries(contractorInfo).filter(([key, value]) => !!value)
    //);

    const formData = Object.values(taxForms?.form_data || {})
      .flat()
      .filter((item) => {
        if (isInitialLoad && skippedFields.includes(item.field)) {
          return {};
        }
        return item;
      })
      .map((item) => ({ [item.field]: item?.current_value }))
      .reduce((acc, currentValue) => ({ ...acc, ...currentValue }), {});

    //detailsForm.setFieldsValue({ ...formData, ...filteredcontractoInfo });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [taxForms, isInitialLoad]);
  //const contractor = location?.state?.contractor;
  /*const contractorInfo = {
    "PAYER'S name, street address, city or town, state or province, country, ZIP or foreign postal code, and telephone no.": `${taxForms.form_data["Form Details"][0]["current_value"]  || ""    }`,
    "PAYER'S TIN": `${taxForms?.form_data?.["Form Details"][1]["current_value"] || ""}`,
    "17 State/Payer's state no.": `${taxForms?.form_data?.["Form Details"][2]["current_value"]|| ""}`,
    "RECIPIENT'S name": `${taxForms?.form_data?.["Form Details"][3]["current_value"]|| ""}`,
    "Street address (including apt. no.)": `${taxForms?.form_data?.["Form Details"][4]["current_value"]|| ""}`,
    "Account number (see instructions)": `${taxForms?.form_data?.["Form Details"][6]["current_value"]|| ""}`,
    "City or town, state or province, country, and ZIP or foreign postal code": `
      ${taxForms?.form_data?.["Form Details"][5]["current_value"]|| ""}`,
  };*/
  const columns = useMemo(
    () => [
      {
        title: "Form Section",
        dataIndex: "field",
        align: "left",
        width: "40%",
        ellipsis: {
          showTitle: false,
        },
        render: (field) => (
          <Tooltip
            color="white"
            overlayInnerStyle={{ backgroundColor: "white", color: "black" }}
            placement="topLeft"
            title={field}
          >
            {field}
          </Tooltip>
        ),
      },
      {
        title: "Field Value",
        sortDirections: ["descend", "ascend"],
        dataIndex: "field",
        render: (record) => {
          const item = Object.values(taxForms?.form_data || {})
            ?.flat()
            ?.find((detail) => detail.field === record);

          const originalData = Object.values(originalTaxForm?.form_data || {})
            ?.flat()
            ?.find((detail) => detail.field === record);

          const isEnabled = editableFields.includes(record);
          return (
            <>
              {item?.data_type !== "checkbox" && (
                <Tooltip
                  color="white"
                  overlayInnerStyle={{
                    backgroundColor: "white",
                    color: "black",
                  }}
                  placement="topLeft"
                  title={originalData?.current_value?.toString()}
                >
                  <Form.Item shouldUpdate name={record}>
                    <Input
                      readOnly={!isEnabled}
                      onClick={() => {
                        if (!isEnabled) {
                          setEditableFields((prevState) => {
                            return Array.from(new Set([...prevState, record]));
                          });
                        }
                      }}
                      // disabled={!isEnabled}
                      onBlur={() => {
                        const hasUpdates =
                          item?.current_value !==
                          detailsForm.getFieldValue(record);
                        if (hasUpdates) {
                          onPressDetailsSave();
                        }
                      }}
                      type={item?.data_type === "numeric" ? "number" : "text"}
                      onDoubleClick={() => {
                        setEditableFields((prevState) => {
                          return Array.from(new Set([...prevState, record]));
                        });
                      }}
                      style={{
                        width: 200,
                        ...(!isEnabled
                          ? {
                              borderColor: "transparent",
                              backgroundColor: "transparent",
                              color: "rgba(0,0,0, 0.85)",
                            }
                          : {}),
                      }}
                      placeholder="Enter Value"
                    />
                  </Form.Item>
                </Tooltip>
              )}
              {item?.data_type === "checkbox" && (
                <Tooltip
                  color="white"
                  overlayInnerStyle={{
                    backgroundColor: "white",
                    color: "black",
                  }}
                  placement="topLeft"
                  title={
                    isEnabled ? originalData?.current_value?.toString() : ""
                  }
                >
                  <Form.Item shouldUpdate name={record}>
                    <Select
                      style={{
                        width: 200,
                        ...(!isEnabled
                          ? {
                              borderColor: "transparent",
                              backgroundColor: "transparent",
                              color: "rgba(0,0,0, 0.85)",
                            }
                          : {}),
                      }}
                      bordered={isEnabled}
                      onBlur={() => {
                        const hasUpdates =
                          item?.current_value !==
                          detailsForm.getFieldValue(record);
                        if (hasUpdates) {
                          onPressDetailsSave();
                        }
                      }}
                      onClick={() => {
                        if (!isEnabled) {
                          setEditableFields((prevState) => {
                            return Array.from(new Set([...prevState, record]));
                          });
                        }
                      }}
                    >
                      <Select.Option value={true}>True</Select.Option>
                      <Select.Option value={false}>False</Select.Option>
                    </Select>
                  </Form.Item>
                </Tooltip>
              )}
            </>
          );
        },
        align: "left",
        width: "25%",
      },
      {
        title: "Edit/Reset",
        width: "15%",
        dataIndex: "field",
        align: "center",
        render: (record) => {
          const originalData = Object.values(originalTaxForm?.form_data || {})
            ?.flat()
            ?.find((detail) => detail.field === record);

          return (
            <Row gutter={20} align={"middle"} justify={"center"}>
              <EditIcon
                onClick={() =>
                  setEditableFields((prevState) => {
                    if (prevState.includes(record)) {
                      const filteredValues = prevState.filter(
                        (item) => item !== record
                      );
                      return setEditableFields(filteredValues);
                    }

                    setEditableFields([...prevState, record]);
                  })
                }
              />
              <ResetIcon
                onClick={() => {
                  setEditableFields((prevState) => {
                    return prevState.filter((item) => item !== record);
                  });
                  detailsForm.setFieldValue(
                    record,
                    originalData?.current_value
                  );
                  onPressDetailsSave(true);
                }}
                style={{ marginLeft: 16 }}
              />
            </Row>
          );
        },
      },
      {
        title: "Status",
        dataIndex: "status",
        align: "left",
        render: (record) => {
          const tagColor =
            record.toLowerCase() === "confirmed"
              ? "green"
              : record.toLowerCase() === "incomplete"
              ? "red"
              : "gold";

          return <Tag color={tagColor}>{record?.toUpperCase()}</Tag>;
        },
        sorter: (a, b) => a.status - b.status,
        width: "15%",
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [taxForms, editableFields, detailsForm, originalTaxForm]
  );

  const onClickGenerate = () => {
    generatePDF.mutate();
  };

  const toggleSubmitModal = () => {
    setSubmitModalVisible((prevState) => !prevState);
  };

  const toggleSuccessModal = () => {
    if(submitModalVisible){
      toggleSubmitModal()
    }
    setSuccessModalVisible((prevState) => !prevState);
  };

  const onClickSubmit = () => {
    toggleSubmitModal();
  };

  const onClickSuccess = () => {
    submitPDF.mutate()
  }

  return (
    <div className={classNames.container}>
      <section className={classNames.innerContent}>
        <Row gutter={20}>
          <Col span={1}>
            <BackIcon onClick={() => navigate(-1)} />
          </Col>
          <Col span={4}>
            <Card
              bodyStyle={{
                paddingTop: 5,
                paddingBottom: 6,
                paddingLeft: 16,
                paddingRight: 16,
              }}
              className={classNames.bankCard}
              style={{ padding: 0, paddingTop: 0 }}
            >
              <Row>
                <Col className={classNames.column}>
                  <Typography>{location?.state?.form?.form_name}</Typography>
                  <Typography>
                    {moment(location?.state?.form?.deadline).format(
                      "MM/DD/YYYY"
                    )}
                  </Typography>
                </Col>
              </Row>
            </Card>
          </Col>
          <Col span={4}>
            <CTAButton
              onClick={() =>
                window.open(
                  "https://www.irs.gov/forms-pubs/about-form-1099-misc"
                )
              }
            >
              Form Instructions
            </CTAButton>
          </Col>
        </Row>
        <Typography.Title level={4} style={{ marginTop: 32, marginBottom: 8 }}>
          Form Details
        </Typography.Title>

        <Form form={detailsForm}>
          <Table
            pagination={false}
            rowSelection={{
              type: "checkbox",
              selectedRowKeys,
              onChange: onSelectChange,
            }}
            loading={
              updateTaxForms.isLoading ||
              getTaxForms.isLoading ||
              generatePDF.isLoading || submitPDF.isLoading || getBusinessDataCollection.isLoading
            }
            dataSource={Object.values(taxForms?.form_data || {})
              ?.flat()
              ?.map?.((item) => ({ ...item, key: item.field }))}
            columns={columns}
          />
        </Form>

        {!getTaxForms.isLoading && (
          <>
            <Row gutter={20}>
              <Col className={classNames.cancelButton} offset={7} span={10}>
                <CTAButton
                  disabled={generatePDF.isLoading || updateTaxForms.isLoading || submitPDF.isLoading}
                  onClick={onPressConfirm}
                >
                  Confirm
                  {selectedRowKeys?.length
                    ? ` (${selectedRowKeys.length})`
                    : ""}
                </CTAButton>
              </Col>
            </Row>
            <Row gutter={20}>
              <Col span={10} offset={7} style={{ marginTop: 24 }}>
                <CTAButton
                  className={classNames.cancelButton}
                  onClick={onClickGenerate}
                  disabled={generatePDF.isLoading || updateTaxForms.isLoading || submitPDF.isLoading}
                >
                  Generate PDF
                </CTAButton>
              </Col>
            </Row>
            <Row gutter={20}>
              <Col span={10} offset={7} style={{ marginTop: 24 }}>
                <CTAButton
                  className={classNames.cancelButton}
                  onClick={onClickSubmit}
                  disabled={generatePDF.isLoading || updateTaxForms.isLoading || submitPDF.isLoading}
                >
                  Submit
                </CTAButton>
              </Col>
            </Row>
          </>
        )}
      </section>
      <Modal
        visible={submitModalVisible}
        title={"Confirm 1099-MISC Submission"}
        width={500}
        onCloseClick={toggleSubmitModal}
      >
        1099-MISC for {location?.state?.contractor?.contractor_name} will be submitted to the IRS. The
        contractor will recieve a copy of this form as well.
        <Row gutter={20} style={{paddingTop: 16}}>
          <Col span={12}>
            <CTAButton
              className={classNames.cancelButton}
              onClick={onClickSuccess}
              disabled={submitPDF.isLoading}
              loading={submitPDF.isLoading}
            >
              Submit
            </CTAButton>
          </Col>
          <Col span={12}>
            <CTAButton
              className={classNames.cancelButton}
              onClick={toggleSubmitModal}
              disabled={submitPDF.isLoading}
            >
              Cancel
            </CTAButton>
          </Col>
        </Row>
        
      </Modal>

      <Modal
        visible={successModalVisible}
        title={"1099-MISC Submitted"}
        width={500}
        onCloseClick={toggleSuccessModal}
      >
        1099-MISC for {location?.state?.contractor?.contractor_name} has been successfully submitted to the IRS. The contractor has been emailed a copy of this form as well. 
      </Modal>
    </div>
  );
};

export default TaxFormDetailsComponent;
