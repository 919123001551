import React, { useEffect, useState, useRef } from "react";
import classNames from "./NyraAI.module.scss";
// import ChatKitty from "@chatkitty/core";
import ChatKitty from 'chatkitty';
import { Input, Avatar, Spin, notification, Skeleton } from "antd";
import logo from "./logo.svg";
import { InboxOutlined, SendOutlined, UserOutlined } from "@ant-design/icons";
import { useBusiness } from "../../../store/StoreProvider";
import { useMutation } from "react-query";
import api from "../../API";
import Message from "../../common/Message/Message";
import Bugsnag from "@bugsnag/js";

function Chatbot({accountData, channel, setChannel, messages, setMessages, isFaqsCollapsed=true}) {
  const secureLocalStore = window.localStorage || window.sessionStorage;
  let { selectedBusiness } = useBusiness();
  // const [channel, setChannel] = useState(null);
  const [username] = useState(secureLocalStore.getItem('email'));
  const [
    // sessionUser
    , setSessionUser
  ] = useState(null);
  const [messageInput, setMessageInput] = useState("");
  // const [messages, setMessages] = useState([]);
  const [, setContacts] = useState([]);
  const [typing, setTyping] = useState(null);
  const [, setStyle] = useState('none');
  const [loading, setLoading] = useState(true);
  const [messageLoading, setMessageLoading] = useState(true);
  const [, setIsLoadingAIResponse] = useState(false);
  const [iframeHeights, setIframeHeights] = useState({});

  const { TextArea } = Input;
  const messagesEndRef = useRef(null);

  // Assigning a default value to selectedBusiness if it's an empty object
  selectedBusiness = Object.keys(selectedBusiness).length === 0 ? accountData : selectedBusiness;

  const chatkitty = ChatKitty.getInstance(process.env.REACT_APP_CHATKITTY_API_KEY);

  const initSession = async () => {
    const result = await chatkitty.startSession({ username });

    console.log("THIS IS LOOPING");

    if (result.succeeded) {
      const session = result.session;
      setSessionUser(session);
      chatkitty.getUsers().then((result) => {
        setContacts(result.paginator.items);
      });
      initChannel(session);
    } else {
      console.log("Failed to start session:", result.error);
    }
  };

  const initChannel = async (user) => {
    const result = await chatkitty.createChannel({
      type: 'DIRECT',
      members: [{ username: 'nyra@otterz.co' }],
    });

    if (result.succeeded) {
      setChannel(result.channel);
      let chat = chatkitty.startChatSession({
        channel: result.channel,
        onReceivedMessage: (message) => {
          if (message.user.id === user.user.id) return;
          setMessages((draft) => [
            ...draft, 
            {
              sender: message.user.name,
              channel_id: result.channel.id,
              message: message.body,
              created_at: message.createdTime,
              business_id: selectedBusiness.business_id,
            }
          ]);
          scrollToBottom();
          notifyUser(message);
        },
        onTypingStarted: (typingUser) => {
          if (typingUser.id !== user.user.id) {
            setTyping(typingUser);
          }
        },
        onTypingStopped: (typingUser) => {
          if (typingUser.id !== user.user.id) {
            setTyping(null);
          }
        },
      });

      if (chat.failed) {
        console.error("Failed to start chat session:", chat.error);
        setLoading(false);
      }
      setLoading(false);
      getMessagesBusiness.mutate({
        business_id: selectedBusiness.business_id,
        channel_id: result.channel.id,
      });

    } else {
      console.error("Failed to create channel:", result.error);
    }
  };

  const notifyUser = (message) => {
    setStyle('initial');
    notification.open({
      message: getCurrentDate(),
      description: `New message from Nyra, ${message.body}`,
      duration: 2,
      onClick: () => {},
    });
  };

  useEffect(() => {
    chatkitty.endSession();
    initSession();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedBusiness.business_id]);

  const getMessagesBusiness = useMutation(data => api.Services.Dashboard.ChatKitty.getMessagesBusiness(data), {
    onSuccess: (data) => {
      if (!data.status) {
        return;
      }
      if (data && data.data) {
        setMessages(data.data);
        setMessageLoading(false);
        scrollToBottom();
      }
    },
    onError: (error) => {
      console.error("getMessagesBusiness error: ", error);
      setMessageLoading(false);
    },
  });

  const sendMessageBusiness = useMutation(
    (data) => api.Services.Dashboard.ChatKitty.sendMessageBusiness(data),
    {
      onSuccess: (data) => {
        if (!data.status) {
          Message({ type: "error", content: "Couldn't get response from Nyra, Please try again" });
          setIsLoadingAIResponse(false);
          return;
        }
        if (data && data.data) {
          getMessagesBusiness.mutate({
            business_id: selectedBusiness.business_id,
            channel_id: channel.id,
          });
          setMessageLoading(false);

          setMessages((prevMessages) => {
            const updatedMessages = [...prevMessages];
            const placeholderIndex = updatedMessages.findIndex((message) => message.isLoading === true);
            if (placeholderIndex !== -1) {
              updatedMessages[placeholderIndex] = {
                sender: "nyra@otterz.co",
                message: data.data.message,
                created_at: new Date().toISOString(),
                business_id: selectedBusiness.business_id,
              };
            }
            scrollToBottom();
            return updatedMessages;
          });

          setIsLoadingAIResponse(false);
        }
      },
      onError: (error) => {
        console.error("AI response error: ", error);
        Bugsnag.notify("error: ", error, "error sending message to AI");
        Message({ type: "error", content: "Couldn't get response from Nyra, Please try again" });
        setMessageLoading(false);
        setIsLoadingAIResponse(false);
      },
    }
  );

  useEffect(() => {
    return () => {
      if (channel) {
        // channel.end();
      }
      chatkitty.endSession();
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [channel]);

  const sendMessageAI = async () => {
    if (!messageInput.trim()) return;

    const userMessage = {
      sender: username,
      message: messageInput.trim(),
      created_at: new Date().toISOString(),
      business_id: selectedBusiness.business_id,
    };

    const aiPlaceholderMessage = {
      sender: "nyra@otterz.co",
      message: "Let's go, working on it! 🤖",
      created_at: new Date().toISOString(),
      business_id: selectedBusiness.business_id,
      isLoading: true,
    };

    setMessages((prevMessages) => [...prevMessages, userMessage, aiPlaceholderMessage]);
    setIsLoadingAIResponse(true);
    setMessageInput("");

    sendMessageBusiness.mutate({
      business_id: selectedBusiness.business_id,
      messageData: {
        channel_id: channel.id,
        message: messageInput.trim(),
        sender: username,
      },
    });

    scrollToBottom();
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  // Event listener to adjust iframe height
  useEffect(() => {
    const handleResize = (event) => {
      if (typeof event.data === "object" && event.data.height && event.data.id) {
        setIframeHeights((prevHeights) => ({
          ...prevHeights,
          [event.data.id]: `${event.data.height}px`,
        }));
      }
    };

    window.addEventListener("message", handleResize);

    return () => {
      window.removeEventListener("message", handleResize);
    };
  }, []);

  const renderStyledText = (text) => {
    text = text.replace(/<b>(.*?)<\/b>/g, "<strong>$1</strong>");
    text = text.replace(/<i>(.*?)<\/i>/g, "<em>$1</em>");
    return <div dangerouslySetInnerHTML={{ __html: text }} />;
  };

  function getCurrentDate() {
    const currentDate = new Date();
    const options = { month: 'short', day: '2-digit', year: 'numeric' };
    return currentDate.toLocaleDateString('en-US', options);
  }

  const scrollToBottom = () => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollTop = messagesEndRef.current.scrollHeight;
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault();
      sendMessageAI();
    }
  };

  return (
    <div className={classNames.wrapper}>
      {(loading || messageLoading) ? (
        <Spin size="large" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }} />
      ) : (
        <>
          <div ref={messagesEndRef} className={classNames.messagesContainer} style={{margin: isFaqsCollapsed ? '35px 10vw 0' : '35px 0.5rem 0',}}>
            {messages.length === 0 ? (
              <div style={{ 
                textAlign: 'center',
                padding: '20px',
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                opacity: '0.5',
                alignItems: 'center',
                gap: '10px',
                justifyContent: 'center' 
              }}>
                <span>Your messages will appear here</span>
                <InboxOutlined style={{ marginLeft: '8px', fontSize: '24px', color: '#82898F' }} />
              </div>
            ) : (
              messages.map((message, index) => (
                message.message !== "" && <div key={index} className={message.sender === username ? classNames.message : classNames.receiver}>
                  <div className={message.sender === username ? classNames.username : classNames.aiUsername}>
                    <div className={classNames.messageAuthor} style={{
                      color: message.sender === username ? "#979797" : "#C85103"
                    }}>{message.sender}</div>
                  </div>
                  <div className={message.sender === username ? classNames.messageContentSender : classNames.messageContent}>
                    <div className={classNames.messageAvatar}>
                      <Avatar icon={message.sender === username ? <UserOutlined /> : <img src={logo} alt="logo" width={"100%"} />} />
                    </div>
                    <div className={classNames.messageBody}>
                      {message.isLoading ? (
                        <><Skeleton active /></>
                      ) : (
                        /<canvas|<svg|chart/i.test(message.message) && message.sender !== username ?         
                        <iframe
                          srcDoc={`<style>
                              body { font-family: sans-serif; }
                              * { margin: 20px; width: 400px; } 
                            </style>
                            <script>
                              // Function to send height to parent
                              function sendHeight() {
                                const height = document.body.scrollHeight;
                                window.parent.postMessage({ height: height, id: '${message.created_at}' }, '*');
                              }
                              window.onload = sendHeight; // Send height on load
                              window.onresize = sendHeight; // Send height on resize
                            </script>
                            ${message?.message?.replace(/```html|```/g, '')}`}
                          title="Embedded Chart"
                          onLoad={(e) => {
                            e.target.contentWindow.postMessage({ id: message.created_at }, "*");
                            e.target.contentWindow.sendHeight(); // Send height immediately after load
                          }}
                          style={{ width: "100%", height: iframeHeights[message.created_at] || '300px', border: "none" }}
                        /> : renderStyledText(message.message)
                      )}
                    </div>
                  </div>
                </div>
              ))
            )}
          </div>
          
          {typing && (
            <div className={classNames.typing}>
              {typing.displayName} is typing...
            </div>
          )}

          <div className={classNames.inputContainer}>
            <TextArea
              placeholder="Ask a query"
              value={messageInput}
              onChange={(e) => setMessageInput(e.target.value)}
              autoSize={{ minRows: 0 }}
              onKeyDown={handleKeyPress}
            />
            <SendOutlined className={classNames.submitBtn} onClick={sendMessageAI} />
          </div>
        </>
      )}
    </div>
  );
}

export default Chatbot;
