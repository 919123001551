import endpoints from "../../../endpoints";

export default async function downloadSelectiveFiles(data){
    let env = process.env.REACT_APP_ENV?.trim();

    const secureLocalStore = window.localStorage || window.sessionStorage;
    const baseURL = endpoints.SERVICES.DASHBOARD.DOCUMENTS_VAULT.DOWNLOAD_FILES[env];
    
    const accessToken = secureLocalStore.getItem('accessToken');

    return await(
        await fetch(`${baseURL}`, {
            method: "POST",
            headers: {
                'Authorization': `Bearer ${accessToken}`,
                'Content-Type': 'application/json'  // This is important to specify the content type
            },
            body: JSON.stringify(data)
        })
    ).json();
}
