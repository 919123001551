import React from 'react';
import { 
  Button,
  // Spin,
  DatePicker, 
  Tabs 
} from 'antd'; // Import Tabs component from Ant Design
import classNames from './Insights.module.scss';
import { useFilter } from '../../../store/StoreProvider';
import CashFlow from './CashFlow';
import ProfitAndLoss from './ProfitAndLoss';
import Insights from './Insights';
import { CTAButton } from '../../common';

const { TabPane } = Tabs; // Destructure TabPane from Tabs
const { RangePicker } = DatePicker; 

const CashFlowAndPL = () => {
  const {
    insightFilter,
    setInsightFilter,
    // dateRange,
    setDateRange,
    showCustomDatePicker,
    setShowCustomDatePicker,
    // applyClicked,
    setApplyClicked,
    inputDateRange,
    setInputDataRange
  } = useFilter();

  const handleDateRangeChange = (dates) => {
    // setDateRange(dates);
    setInputDataRange(dates);
  };

  const renderCustomDateRangePicker = () => {
    return (
      <div style={{
        position: 'absolute',
        top: '50px',
        // right: '10px',
        display: 'flex',
        zIndex: '999',
        // flexDirection: 'column',
        alignItems: 'center'
      }}>
        <RangePicker
          onChange={handleDateRangeChange}
          format="YYYY-MM"
          placeholder={['Start Month', 'End Month']}
          picker="month"
          separator={
            <svg width="1" height="35" viewBox="0 0 1 35" fill="none" xmlns="http://www.w3.org/2000/svg">
            <line x1="0.5" y1="35" x2="0.5" stroke="#82898F"/>
            </svg>
          }
        />
        <CTAButton onClick={() => {
          console.log('inputDateRange', inputDateRange);
          setDateRange(inputDateRange);
          setApplyClicked(prev => prev + 1);
        }}
        style={{
          width: '25%',
          // border: '0px',
          // background: 'rgb(0, 0, 0)',
          height: '54px',
          borderRadius: '0px 8px 8px 0px'
        }}
        >Apply</CTAButton>
      </div>
    );
  };

  return (
    <div className={classNames.container}>
      {/* <section className={classNames.greetingsSection}>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <h1 style={{ color: '#82898F', margin: 0 }}>Hello, {userData?.first_name}</h1>
        </div>
      </section> */}

      {/* Add Ant Design Tabs below the greeting */}
      <div style={{
        width: '100%',
        position: 'relative',
        // display: 'flex',
        // gap: '30px',
        // justifyContent: 'flex-end',
      }}>
        <Tabs defaultActiveKey="1">
          <TabPane tab="Overview" key="1">
              <Insights />
          </TabPane>
          <TabPane tab="Cash Flow" key="2">
              <CashFlow />
          </TabPane>
          <TabPane tab="Profit and Loss" key="3">
              <ProfitAndLoss />
          </TabPane>
        </Tabs>
        <div style={{
          display: 'flex',
          gap: '30px',
          justifyContent: 'flex-end',
          position: 'absolute',
          right: '17px',
          top: '0',
          padding: '10px 45px 0vw'
        }}>
          {['Week', 'Month', 'Year', 'Custom'].map((filter, index) => (
            <Button 
              key={index}
              type='link' 
              style={{ 
                backgroundColor: insightFilter === filter.toLowerCase() ? '#616161' : 'white', 
                color: insightFilter === filter.toLowerCase() ? 'white' : 'black',
                borderRadius: '20px',
                fontWeight: 'bold',
                transition: 'background-color 0.3s, color 0.3s', // Add transition for smooth hover effect
              }} 
              onClick={() => {
                setInsightFilter(filter.toLowerCase());
                setShowCustomDatePicker(filter.toLowerCase() === 'custom');
              }}
            >
              {filter}
            </Button>
          ))}
          {showCustomDatePicker && renderCustomDateRangePicker()} {/* Render date picker for custom range only if showCustomDatePicker is true */}
        </div>
      </div>
    </div>
  );
};

export default CashFlowAndPL;
