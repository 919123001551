import React from 'react';

import Check from "remixicon-react/CheckLineIcon";
import Close from "remixicon-react/CloseLineIcon";

import classNames from './styles.module.scss'
import { CTAButton } from '../../common';

const SubscriptionPlanCard = ({ planName, price, description, subDescription, info, tag, planInclude, paymentRate, interchangeRate, features, onSubscribe, selected, save, transactionVol, addOn, isLoading }) => {
  console.log("isLoading: ", isLoading);

  return (
    <div className={classNames.subscriptionCard}>
      {planName === "Elite" ? (
        <div className={classNames.saveBadge}>
          Recommended
        </div>
      ) : (
        <div className={classNames.saveBadge} style={{opacity: 0}}>
          Buffer Block!
        </div>
      )}
      <div className={classNames.subscriptionCardHeader}>
          <h2 className={classNames.planName} style={{fontWeight: 'bold'}}>{planName}</h2>
          <p style={{opacity: planName !== "Starter" && planName !== "Growth" ? 1 : 0, margin: '1rem 0 0 0'}}>Starting at</p>
          <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '1vw'}}>
            <h4 className={classNames.price}><span><sup>$</sup>{price}</span></h4>
            <p className={classNames.planDescription}>
                <span>{description}</span><br/>
            </p>
          </div>
          <span style={{opacity: info ? 1 : 0}} className={classNames.subDescription}>
            <b>{info}</b>
          </span>
          {
            tag ? (
              <span className={classNames.subDescription} style={{marginTop: '1rem', opacity: tag ? 1 : 0}}>{tag}</span>
            ) : (
              <span className={classNames.subDescription} style={{marginTop: '1rem', opacity: 0}}>Buffer Block!</span>
            )
          }
      </div>
      <div className={classNames.cardContent} style={{padding: planName === "Starter" ? "20px 0 2vw" : "20px 0"}}>
        {/* <p style={{textAlign: "left", width: "90%", fontWeight: "700", marginBottom: 0}}>What's included : <span style={{color: "#7372FF"}}>{planInclude}</span></p> */}
        {/* <p style={{textAlign: "center", width: "100%", marginBottom: 0, background: "#D9D9D9", padding: '10px'}}>{planInclude}</p> */}
        <ul className={classNames.featuresList}>
            {features.map((feature, index) => (
            <li key={`${planName}_${index}`} style={{opacity: feature.isAvailable ? 1 : 0, textAlign: 'left'}}>
                <div className={classNames.featureItem}>
                  {feature.isAvailable ?<Check className={classNames.featureIcon} color="#1cbd83" size={16} />:<Close className={classNames.featureIcon} color='#dd2c2c' size={16} />}<div> {`${feature.name} `} {feature.extraInfo ? (<span>(<b style={{color: '#1cbd83'}}>{feature.extraInfo}</b>)</span>) : null}</div>
                </div>
            </li>
            ))}
        </ul>
        {/* <div style={{display: 'flex', flexDirection: 'column', margin: '2px 0px 0px', width: "100%", textAlign: "left", 
          // position: "absolute", top: "160px"
        }}>
          <p style={{margin: "5px 0", textAlign: "center", width: "100%", marginBottom: 0, background: "#D9D9D9", padding: '10px'}}>Payments Processing Rates :</p>
          <p style={{margin: "20px 20px 0"}}>{paymentRate}</p>
          {
            planName === "Pro" && !interchangeRate ? (
              <p style={{padding: "0px 20px 0"}}> <span style={{color: "#7372FF", fontWeight: '600', opacity: 0}}>Buffer</span></p>
            ) : <p style={{padding: "0px 20px 0"}}> (Interchange + <span style={{color: "#", fontWeight: '600'}}>{interchangeRate}</span>)</p>
          }
          <p style={{padding: "0px 20px 0"}}>Hardware POS Solutions (Starting at $0)</p>
        </div> */}
        {/* {
          addOn ? (
            <div className={classNames.addOn}>
              <strong>Add-on: </strong>Tax Filing for <span style={{color: '#7372FF', fontWeight: '600'}}>{addOn}</span>
            </div>
          ) : (
            <div className={classNames.addOn}style={{opacity: '0'}}>
              <strong>Don't look: </strong><span>Ahhhh!</span>
            </div>
          )
        } */}
      </div>
      <div className={classNames.cardFooter}>
        <CTAButton type={selected ? '' : 'primary'} disabled={selected || isLoading} className={[selected && classNames.selected, classNames.button].filter(Boolean).join(' ')} onClick={selected ? () => null : onSubscribe}>{selected ? "Active" :"Subscribe"}</CTAButton>
      </div>
    </div>
  );
};

export default SubscriptionPlanCard;
