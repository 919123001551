import React, { useState, useEffect, useRef } from 'react';
import classNames from './ChatPanel.module.scss';
import NyraAI from '../NyraAI/NyraAI';
import Chatbot from '../Chatbot/Chatbot';
import { Collapse } from 'antd';
import { CTAButton, Message, Select } from '../../common';
import { useLocation } from 'react-router-dom';
import { QuestionCircleOutlined, PushpinOutlined } from '@ant-design/icons';
import api from '../../API';
import { useAccount } from '../../../store/StoreProvider';
import Bugsnag from '@bugsnag/js';

const { Panel } = Collapse;
const { Option } = Select;

const ChatPanel = () => {
  const location = useLocation();
  const { chatType } = location.state || {};
  let { selectedBusiness } = useAccount();
  const [channel, setChannel] = useState(null);
  const [messages, setMessages] = useState([]);
  const [selectedTab, setSelectedTab] = useState(chatType === 'accountant' ? 'accountant' : 'nyra');
  const [isFaqsCollapsed, setIsFaqsCollapsed] = useState(true);
  const [isPinned, setIsPinned] = useState(false);
  const faqRef = useRef(null);

  const handleTabChange = (value) => {
    setSelectedTab(value);
  };

  const handleClearChat = () => {
    if (!channel) return;

    api.Services.Dashboard.ChatKitty.clearNyraChat({
      business_id: selectedBusiness.business_id,
      channel_id: channel.id,
    })
    .then((response) => {
        setMessages([]);
        Message({ type: "success", content: "Nyra chat history cleared successfully" });
      })
      .catch((error) => {
        console.error(error);
        Bugsnag.notify(error, 'Failed to clear nyra chat');
      });
  };

  const toggleFaqs = () => {
    if (!isPinned) {
      setIsFaqsCollapsed(!isFaqsCollapsed);
    }
  };

  const togglePin = () => {
    setIsPinned(!isPinned);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (faqRef.current && !faqRef.current.contains(event.target) && !isPinned) {
        setIsFaqsCollapsed(true);
      }
    };

    if (!isFaqsCollapsed) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isFaqsCollapsed, isPinned]);

  const faqs = [
    {
      question: "What questions can Nyra answer? ",
      answer: "Nyra can give you insight into your financial performance and analyze specific areas such as revenue, expenses, etc. Please contact your dedicated accountant for more detailed reporting and complex accounting and tax questions. "
    },
    {
      question: "How can I get better responses in chat?",
      answer: "If Nyra is not providing the answer you are looking for please try using “Clear Chat” button to receive more updated responses."
    },
    {
      question: "What documents do I need to file my taxes?",
      answer: "The documents required to file your taxes include income statements, expense receipts, and any relevant tax forms provided by Otterz."
    },
    {
      question: "Can Otterz assist with BOIR filing?",
      answer: "Yes, Otterz provides support for BOIR filing. You can contact your accountant for assistance with BOIR filing."
    },
    {
      question: "Can Otterz assist with filing amended returns for my business?",
      answer: "Yes, Otterz provides support for filing amended returns. You can contact your accountant for assistance with amended returns."
    },
    {
      question: "How does Otterz ensure the security and confidentiality of my tax information?",
      answer: "Otterz employs robust security measures to safeguard your tax information, including encryption, multi-factor authentication, and regular security audits."
    },
    {
      question: "What support does Otterz offer if I receive an audit notice from the IRS?",
      answer: "If you receive an audit notice from the IRS, Otterz provides assistance and guidance throughout the audit process. You can contact our support team for personalized support."
    }
  ];

  return (
    <div className={classNames.wrapper}>
      <div className={classNames.container}>
        <div className={classNames.chatColumn}>
          <div className={classNames.switchColumn} style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <div style={{ fontSize: '1.2rem', color: '#82898F', display: 'flex', alignItems: 'center' }}>
              You're chatting with
              <Select
                value={selectedTab}
                onChange={handleTabChange}
                style={{ marginLeft: '0.5rem', width: 200 }}
              >
                <Option value="nyra">
                  <span style={{ color: '#c85103', fontSize: '1.2rem' }}>Nyra</span>
                </Option>
                <Option value="accountant">
                  <span style={{ color: '#c85103', fontSize: '1.2rem' }}>Accountant</span>
                </Option>
              </Select>
              {selectedTab === 'nyra' && (
                <CTAButton onClick={handleClearChat} style={{ marginLeft: '1rem', width: 'fit-content' }}>Clear Chat</CTAButton>
              )}
            </div>
            <div onClick={toggleFaqs} style={{ cursor: 'pointer', display: 'flex', alignItems: 'center', fontSize: '1.2rem', color: '#82898F' }}>
              <QuestionCircleOutlined style={{ marginRight: '0.5rem' }} />
              FAQs
            </div>
          </div>
          {selectedTab === 'nyra' ? (
            <NyraAI
              channel={channel}
              setChannel={setChannel}
              messages={messages}
              setMessages={setMessages}
              isFaqsCollapsed={isFaqsCollapsed}
            />
          ) : (
            <Chatbot receiver={process.env.REACT_APP_ACCOUNTANT_EMAIL} />
          )}
        </div>

        <div
          ref={faqRef}
          className={classNames.faqColumn}
          style={{
            width: isFaqsCollapsed ? '0' : '500px',
            overflow: isFaqsCollapsed ? 'hidden' : 'auto',
            transition: 'width 0.3s',
          }}
        >
          <Collapse activeKey={isFaqsCollapsed ? [] : ['faqs']} bordered={false}>
            <Panel
              header={
                <div style={{ display: 'flex', alignItems: 'center' }} onClick={togglePin}>
                  <PushpinOutlined style={{ marginRight: '0.5rem', opacity: isFaqsCollapsed ? 0 : 1 }} />
                  <span>{isPinned ? 'Unpin FAQs' : 'Pin FAQs'}</span>
                </div>
              }
              key="faqs"
              style={{ margin: '1rem', border: 'none' }}
              showArrow={false}
            >
              {faqs.map((item, index) => (
                <Collapse key={index} bordered={false}>
                  <Panel header={item.question} key={index}>
                    <p>{item.answer}</p>
                  </Panel>
                </Collapse>
              ))}
            </Panel>
          </Collapse>
        </div>
      </div>
    </div>
  );
};

export default ChatPanel;
