import React, { useEffect, useState } from 'react';
import { Tabs } from 'antd';
import { Route, Routes, useLocation } from 'react-router-dom';
import { useMutation } from 'react-query';
import Bugsnag from "@bugsnag/js";
import classNames from './CustomerData.module.scss';
// import BusinessProfile from './Profile/BusinessProfile';
import DocumentVaultComponent from '../../DocumentVault/DocumentVault/DocumentVaultComponent';
// import BookKeeperChat from '../../OtterzAI/BookkeeperSide';
import api from '../../API';
import { Message } from '../../common';
import Chatbot from '../../OtterzAI/NyraAI/NyraAI';
import UserChat from '../../OtterzAI/Chatbot/Chatbot';
import Categorization from './Categorization/Categorization';
import Contractors from './Contractors/Contractors';
import { TaxFormsComponent } from './Taxes/TaxForms';
import { TaxFormDetailsComponent } from './Taxes/TaxFormDetails';
import { TaxQueriesComponent } from './Queries/TaxQueries';
import Profile from '../../Settings/Profile/Profile';

const { TabPane } = Tabs;

// const Bookkeeping = () => {
//   return <div>Bookkeeping Tab Content</div>;
// };

// const Taxes = () => {
//   return <div>Taxes Tab Content</div>;
// };

// const Chat = () => {
//   return <div>Chat Tab Content</div>;
// };

const CustomerData = () => {
  const secureLocalStore = window.localStorage || window.sessionStorage;

  const [, setProfileData] = useState({}); // [profileData, setProfileData
  const [channel, setChannel] = useState(null);
  const [messages, setMessages] = useState([]);

  const [activeKey, setActiveKey] = useState(secureLocalStore.getItem('tabData') || "1");
  const location = useLocation();
  const rowData = location.state.rowData;
  const accountData = {
    business_id: rowData?.business_id,
    first_name: rowData?.admin
  }

  secureLocalStore.setItem('cstData', JSON.stringify(rowData));
  // const secureLocalStore = window.localStorage || window.sessionStorage;

    /* Mutation definition for API calls */
  const getBusinessProfile = useMutation(data => api.Services.AccountantsDashboard.BusinessDetails.getBusinessProfile(data), {
    onSuccess: (data) => {
      if (!data.status) {
        Message({ type: "error", content: data.errors[0].message });
      }
      if (data && data.data) {
        setProfileData(data.data)
      }
    },
    onError: (error) => {
      Bugsnag.notify("Error in fetching businesses: getBusinessForAccountant", error, error.message)
      Message({ type: "error", content: error.message });
    },
  });

  useEffect(() => {
    getBusinessProfile.mutate(rowData.business_id)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChange = (key) => {
    setActiveKey(key)
    secureLocalStore.setItem('tabData', key);
  }

  return (
    <div className={classNames.customerData}>
      <header className={classNames.header}>
        <h1>{rowData?.business_name}</h1>
      </header>
      <div className={classNames.content}>
        <Tabs defaultActiveKey="1" activeKey={activeKey}  className={classNames.tabs} onChange={onChange}>
          <TabPane tab="Profile" key="1">
            {/* <BusinessProfile profileData={rowData} /> */}
            <Profile accountData={accountData} rowData={rowData} isClient={false} />
          </TabPane>
          <TabPane tab="Manage Documents" key="2">
            <DocumentVaultComponent accountData={accountData} isClient={false} />
          </TabPane>
          {/* <TabPane tab="Taxes" key="3">
            <Routes>
              <Route path={"/details/:formId"} element={<TaxFormDetailsComponent profileData={rowData} onPressTab={onChange}/>} />
              <Route path={"/"} element={<TaxFormsComponent profileData={rowData}/>} />
            </Routes>
            
          </TabPane> */}
          {/* <TabPane tab="Taxes" key="4">
            <Routes>
              <Route path={"/details/:formId"} element={<TaxFormDetailsComponent profileData={rowData} onPressTab={onChange}/>} />
              <Route path={"/"} element={<TaxFormsComponent profileData={rowData}/>} />
            </Routes>
            
          </TabPane> */}
          <TabPane tab="Taxes" key="4">
            <Routes>
              <Route path={"/details/:formId"} element={<TaxFormDetailsComponent profileData={rowData} onPressTab={onChange}/>} />
              <Route path={"/"} element={<TaxFormsComponent profileData={rowData}/>} />
            </Routes>
            
          </TabPane>
          <TabPane tab="Chat" key="5" style={{height: '80vh'}}>
            <UserChat accountData={accountData} receiver={`${rowData?.email}`} />
          </TabPane>
          <TabPane tab="Nyra AI" key="6" style={{height: '80vh'}}>
            <Chatbot accountData={accountData} channel={channel} setChannel={setChannel} messages={messages} setMessages={setMessages} />
          </TabPane>
          <TabPane tab="Categorization" key="7" style={{
              // height: '80vh'
            }}>
            <Categorization profileData={rowData} />
          </TabPane>
          <TabPane tab="Contractors" key="8" style={{height: '80vh'}}>
            <Contractors profileData={rowData} />
          </TabPane>
          <TabPane tab="Queries" key="9" style={{height: '80vh'}}>
            <TaxQueriesComponent profileData={rowData} />
          </TabPane>
          {/* <TabPane tab="Contractors" key="8" style={{height: '80vh'}}>
            <Contractors profileData={rowData} />
          </TabPane>
          <TabPane tab="Queries" key="9" style={{height: '80vh'}}>
            <TaxQueriesComponent />
          </TabPane> */}
          {/* <TabPane tab="Contractors" key="8" style={{height: '80vh'}}>
            <Contractors profileData={rowData} />
          </TabPane>
          <TabPane tab="Queries" key="9" style={{height: '80vh'}}>
            <TaxQueriesComponent />
          </TabPane> */}
        </Tabs>
      </div>
    </div>
  );
};

export default CustomerData;
