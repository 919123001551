import React, { useEffect, useState } from 'react';
import { 
  // Button, 
  Spin,
  Tooltip, 
  // DatePicker 
} from 'antd';
import dayjs from 'dayjs';
import { Bar } from 'react-chartjs-2';
import { InfoCircleOutlined } from "@ant-design/icons";
import classNames from './Insights.module.scss';
import { useMutation } from 'react-query';
import api from '../../API';
import { Message } from '@chatscope/chat-ui-kit-react';
import { useAccount, useFilter } from '../../../store/StoreProvider';
// import Fall from '../icons/Fall';
// import Rise from '../icons/Rise';
// import BeigeLegend from '../icons/BeigeLegend';
// import GrayLegend from '../icons/GrayLegend';
// import { CTAButton } from '../../common';

// const { RangePicker } = DatePicker;

const CashFlow = () => {
  const { 
    // userData, 
    selectedBusiness 
  } = useAccount();
  const {
    insightFilter,
    dateRange,
    setDateRange,
    applyClicked,
  } = useFilter();
  const [loading, setLoading] = useState(true);
  const [cashflowData, setCashflowData] = useState(null);
  const [
    // dataRangeAvgMode
    ,setDateRangeAvgMode] = useState("MoM");

  const getCashflow = useMutation(data => api.Services.Dashboard.BusinessOverview.v2.getCashflowV2(data), {
    onSuccess: (data) => {
      if (!data.status) {
        Message({ type: "error", content: data.errors[0].message });
        return;
      }

      if (data && data.data) {
        console.log("Cashflow Fetched", data.data);
        setCashflowData(data.data);

        setLoading(false);
      }
    },
    onError: (error) => {
      console.error("Signup error: ", error);
      Message({ type: "error", content: error.message });
      setLoading(false);
    },
  });

  useEffect(() => {
    if (selectedBusiness.business_id) {
      fetchCashflowData();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedBusiness, insightFilter, dateRange, applyClicked]);

  const fetchCashflowData = () => {
    let filterValue = {};
    if (insightFilter === 'week') {
      setDateRangeAvgMode('WoW')
    } else if (insightFilter === "year") {
      setDateRangeAvgMode('YoY')
    } else {
      setDateRangeAvgMode('MoM')
    }
    if (insightFilter === 'custom' && dateRange && dateRange.length === 2) {
      filterValue = {
        filter_key: "custom",
        filter_value: {
          start_date: dateRange[0].format("YYYY-MM"),
          end_date: dateRange[1].format("YYYY-MM")
        }
      };
    } else {
      if (insightFilter === 'custom') {
        // filterValue = {
        //   filter_key: "month",
        //   filter_value: {}
        // };
      } else {
        setDateRange(null);
        filterValue = {
          filter_key: insightFilter,
          filter_value: {}
        };
      }
    }

    if (Object.keys(filterValue).length > 0) {
      getCashflow.mutateAsync({
        business_id: selectedBusiness.business_id,
        body: filterValue,
      });
    }
  };

  const isDataAvailable = (data) => {
    console.log("Data", data);
    return data !== null && data !== undefined && Object.keys(data).length > 0;
  };

  const customColors = ['#E1BB80', '#BFB1A1', '#7B6B43', '#352208', '#EBE5D4', '#E1BB80CC'];

  const stackedBarChartOptions = {
    responsive: true,
    scales: {
      x: {
        stacked: true,
        grid: {
          display: false,
        },
        ticks: {
          font: {
            size: 16,
          }
        },
      },
      y: {
        stacked: true,
        suggestedMin: 0,
        grid: {
          display: true,
        },
        ticks: {
          font: {
            size: 16,
          },
          autoSkip: false,
          maxTicksLimit: 8,
          callback: function (value, index, values) {
            return `$${value.toLocaleString()}`;
          }
          // stepSize: 2100
        },
      }
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: true,
      },
    },
  }

  const groupedBarChartOptions = {
    responsive: true,
    scales: {
      x: {
        stacked: false,
        grid: {
          display: false,
        },
        ticks: {
          font: {
            size: 16,
          },
          // stepSize: 3000
        },
      },
      y: {
        suggestedMin: 0,
        grid: {
          display: true,
        },
        ticks: {
          font: {
            size: 16,
          },
          autoSkip: false,
          maxTicksLimit: 8,
          callback: function (value, index, values) {
            return `$${value.toLocaleString()}`;
          }
          // stepSize: 4000
        },
      }
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: true,
      },
    },
  }

  // const convertToAbsolutePercentage = (percentageString) => {
  //   if (percentageString === null || percentageString === undefined) {
  //     return '0%';
  //   }
  //   return percentageString.replace('-', '');
  // };

  const getWeekNumber = (dateStr) => {
    const date = new Date(dateStr);
    const startOfYear = new Date(date.getFullYear(), 0, 1);
    const pastDaysOfYear = (date - startOfYear) / 86400000;
    return Math.ceil((pastDaysOfYear + startOfYear.getDay() + 1) / 7);
  };

  const convertLabel = (label) => {
    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

    if (/^\d{4}-\d{2}$/.test(label)) {
      const [year, month] = label.split("-");
      return `${monthNames[parseInt(month) - 1]}'${year.slice(2)}`;
    }

    if (/^\d{4}-\d{2}-\d{2}$/.test(label)) {
      const weekNumber = getWeekNumber(label);
      return `W${weekNumber}`;
    }

    return label; // Return the label as is if it doesn't match any format
  };

  // Manipulate cash out data to render it as negative
  // const cashOutData = Object.values(cashflowData?.net_cashflow_chart || {}).map(entry => -entry.cash_out);

  const chartDataCashflow = {
    labels: Object.keys(cashflowData?.net_cashflow_chart || {}).map(convertLabel),
    datasets: [
      // {
      //   label: 'Net Cashflow',
      //   data: Object.values(cashflowData?.net_cashflow_chart || {}).map(entry => entry.cash_in - entry.cash_out),
      //   type: 'line',
      //   borderColor: "#7B6B43",
      //   fill: false,
      // },
      {
        label: 'Net Cashflow',
        data: Object.values(cashflowData?.net_cashflow_chart || {}).map(entry => entry?.net_cashflow),
        backgroundColor: "#E1BB80",
        borderRadius: 5,
      },
      // {
      //   label: 'Cash In',
      //   data: Object.values(cashflowData?.net_cashflow_chart || {}).map(entry => entry.cash_in),
      //   backgroundColor: "#E1BB80",
      //   borderRadius: 5,
      // },
      // {
      //   label: 'Cash Out',
      //   data: cashOutData,
      //   backgroundColor: "#BFB1A1",
      //   borderRadius: 5,
      // }
    ],
  };

  const chartDataBalance = {
    labels: Object.keys(cashflowData?.opening_vs_closing_balance || {}).map(convertLabel),
    datasets: [
      {
        label: 'Closing Balance',
        data: Object.values(cashflowData?.opening_vs_closing_balance || {}).map(entry => entry.closing_balance),
        backgroundColor: customColors[0],
        borderRadius: 5,
        borderSkipped: false,
      },
    ],
  };

  return (
    <div className={classNames.container}>

      {loading ? (
        <Spin size="large" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100vh' }} />
      ) : (
        <>
          <section className={classNames.innerContent} style={{gap: '0', background: 'white', margin: '0.5rem', height: '80vh'}}>
            <p style={{color: '#1D1C1C80', fontSize: '0.75rem', padding: '10px 0px 0 36px', opacity: !isDataAvailable(cashflowData?.net_cashflow_chart) ? 0 : 1}}>As of bookkeeping on {dayjs(cashflowData?.net_cashflow_chart_last_updated).format('DD MMMM, YYYY')}</p>
            
            {/* Business Overview */}
            <div className={classNames.card} style={{
                border: 'none',
                boxShadow: 'none',
                padding: '0 20px',
                // filter: Object.entries(cashflowData).length === 0 ? "blur(5px)" : null
            }}>
              <div className={classNames.gridCashflow}>
                {/* First Row */}
                <div className={classNames.card} style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  position: 'relative'
                }}>
                  {getCashflow.isLoading && (
                    <div className={classNames.noDataOverlay} style={{fontSize: '1vw', alignItems: 'center', paddingBottom: '15px', filter: 'blur(2px)', background: '#ffffffbf', zIndex: '1000'}}>
                      <Spin />
                    </div>
                  )}
                  {!isDataAvailable(cashflowData['30_day_cash_in_improvement']) && (
                    <div className={classNames.noDataOverlay} style={{fontSize: '1vw', alignItems: 'end', paddingBottom: '15px'}}>
                      No data available yet! <br />
                      {/* We're currently reconciling your books. <br />
                      The data will be displayed here soon. */}
                    </div>
                  )}
                  <div className={classNames.cardText}>
                    {/* <h3 style={{fontSize: '1rem'}}>30 days Cash In <span style={{color: 'gray', fontSize: '0.8rem'}}>(rolling)</span></h3> */}
                    <h3 style={{fontSize: '1rem'}}>
                      Average Cash In
                      <Tooltip title="Average Cash In: Average cash received per month (current year).">
                        <InfoCircleOutlined style={{color: '#1D1C1C80', fontSize: '0.8rem', marginLeft: '10px'}} />
                      </Tooltip>
                    </h3>
                    {/* <p style={{color: '#1D1C1C80', fontSize: '0.75rem', opacity: !isDataAvailable(cashflowData['30_day_cash_in_improvement']) ? 0 : 1}}>As of bookkeeping on {dayjs(cashflowData['30_day_cash_in_last_updated']).format('DD MMMM, YYYY')}</p> */}
                  </div>
                  <div className={classNames.cardText} style={{opacity: !isDataAvailable(cashflowData['30_day_cash_in_improvement']) ? 0 : 1, alignItems: 'flex-end'}}>
                    <p style={{fontSize: '1.8rem'}}><sup style={{top: '-0.4em'}}>$</sup>{cashflowData && cashflowData['30_day_cash_in']?.toLocaleString('en-US', {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2
                        })}</p>
                    {/* <p style={{color: '#313D44'}}>{parseFloat(cashflowData && cashflowData['30_day_cash_in_improvement']) < 0 ? <Fall /> : <Rise />} <span style={{color: parseFloat(cashflowData['30_day_cash_in_improvement']) < 0 ? "#99161680" : '#2E9A53', fontWeight: 'bold'}}>{cashflowData && convertToAbsolutePercentage(cashflowData['30_day_cash_in_improvement'])}</span> vs last month</p> */}
                    <p style={{color: '#313D44', fontSize: '0.8rem'}}>Per Month</p>
                  </div>
                </div>

                <div className={classNames.card} style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  position: 'relative'
                }}>
                  {getCashflow.isLoading && (
                    <div className={classNames.noDataOverlay} style={{fontSize: '1vw', alignItems: 'center', paddingBottom: '15px', filter: 'blur(2px)', background: '#ffffffbf', zIndex: '1000'}}>
                      <Spin />
                    </div>
                  )}
                  {!isDataAvailable(cashflowData['30_day_cash_burn_improvement']) && (
                    <div className={classNames.noDataOverlay} style={{fontSize: '1vw', alignItems: 'end', paddingBottom: '15px'}}>
                      No data available yet! <br />
                      {/* We're currently reconciling your books. <br />
                      The data will be displayed here soon. */}
                    </div>
                  )}
                  <div className={classNames.cardText}>
                    {/* <h3 style={{fontSize: '1rem'}}>30 days Cash Burn <span style={{color: 'gray', fontSize: '0.8rem'}}>(rolling)</span></h3> */}
                    <h3 style={{fontSize: '1rem'}}>
                      Average Cash Burn
                      <Tooltip title="Average Cash Burn: Average cash spent per month (current year).">
                        <InfoCircleOutlined style={{color: '#1D1C1C80', fontSize: '0.8rem', marginLeft: '10px'}} />
                      </Tooltip>
                    </h3>
                    {/* <p style={{color: '#1D1C1C80', fontSize: '0.75rem', opacity: !isDataAvailable(cashflowData['30_day_cash_burn_improvement']) ? 0 : 1}}>As of bookkeeping on {dayjs(cashflowData['30_day_cash_burn_last_updated']).format('DD MMMM, YYYY')}</p> */}
                  </div>
                  <div className={classNames.cardText} style={{opacity: !isDataAvailable(cashflowData['30_day_cash_burn_improvement']) ? 0 : 1, alignItems: 'flex-end'}}>
                    <p style={{fontSize: '1.8rem'}}><sup style={{top: '-0.4em'}}>$</sup>{cashflowData && cashflowData['30_day_cash_burn']?.toLocaleString('en-US', {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2
                        })}</p>
                    {/* <p style={{color: '#313D44'}}>{parseFloat(cashflowData && cashflowData['30_day_cash_burn_improvement']) < 0 ? <Fall /> : <Rise />} <span style={{color: parseFloat(cashflowData['30_day_cash_burn_improvement']) < 0 ? "#99161680" : '#2E9A53', fontWeight: 'bold'}}>{cashflowData && convertToAbsolutePercentage(cashflowData['30_day_cash_burn_improvement'])}</span> vs last month</p> */}
                    <p style={{color: '#313D44', fontSize: '0.8rem'}}>Per Month</p>
                  </div>
                </div>

                <div className={classNames.card} style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  position: 'relative'
                }}>
                  {getCashflow.isLoading && (
                    <div className={classNames.noDataOverlay} style={{fontSize: '1vw', alignItems: 'center', paddingBottom: '15px', filter: 'blur(2px)', background: '#ffffffbf', zIndex: '1000'}}>
                      <Spin />
                    </div>
                  )}
                  {!isDataAvailable(cashflowData['cash_on_hand_improvement']) && (
                    <div className={classNames.noDataOverlay} style={{fontSize: '1vw', alignItems: 'end', paddingBottom: '15px'}}>
                      No data available yet! <br />
                      {/* We're currently reconciling your books. <br />
                      The data will be displayed here soon. */}
                    </div>
                  )}
                  <div className={classNames.cardText}>
                    {/* <h3 style={{fontSize: '1rem'}}>Cash on Hand</h3> */}
                    <h3 style={{fontSize: '1rem'}}>
                      Average Cash Balance
                      <Tooltip title="Average Cash Balance: Average cash balance per month (current year).">
                        <InfoCircleOutlined style={{color: '#1D1C1C80', fontSize: '0.8rem', marginLeft: '10px'}} />
                      </Tooltip>
                    </h3>
                    {/* <p style={{color: '#1D1C1C80', fontSize: '0.75rem', opacity: !isDataAvailable(cashflowData['cash_on_hand_improvement']) ? 0 : 1}}>As of bookkeeping on {dayjs(cashflowData?.cash_on_hand_last_updated).format('DD MMMM, YYYY')}</p> */}
                  </div>
                  <div className={classNames.cardText} style={{opacity: !isDataAvailable(cashflowData['cash_on_hand_improvement']) ? 0 : 1, alignItems: 'flex-end'}}>
                    <p style={{fontSize: '1.8rem'}}><sup style={{top: '-0.4em'}}>$</sup>{cashflowData?.cash_on_hand?.toLocaleString('en-US', {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2
                        })}</p>
                    {/* <p style={{color: '#313D44'}}>{parseFloat(cashflowData?.cash_on_hand_improvement) < 0 ? <Fall /> : <Rise />} <span style={{color: parseFloat(cashflowData?.cash_on_hand_improvement) < 0 ? "#99161680" : '#2E9A53', fontWeight: 'bold'}}>{convertToAbsolutePercentage(cashflowData?.cash_on_hand_improvement)}</span> vs last month</p> */}
                    <p style={{color: '#313D44', fontSize: '0.8rem'}}>Per Month</p>
                  </div>
                </div>
              </div>
            </div>

            <div className={classNames.card} style={{
                border: 'none',
                boxShadow: 'none',
                padding: '0 20px',
                // filter: Object.entries(cashflowData).length === 0 ? "blur(5px)" : null
            }}>
              <div className={classNames.grid}>
                <div className={classNames.card} style={{position: 'relative'}}>
                  {getCashflow.isLoading && (
                    <div className={classNames.noDataOverlay} style={{fontSize: '1vw', alignItems: 'center', paddingBottom: '15px', filter: 'blur(2px)', background: '#ffffffbf', zIndex: '1000'}}>
                      <Spin />
                    </div>
                  )}
                  {!isDataAvailable(cashflowData?.net_cashflow_chart) && (
                    <div className={classNames.noDataOverlay}>
                      No data available yet! <br />
                      We're currently reconciling your books. <br />
                      The data will be displayed here soon.
                    </div>
                  )}
                  <div style={{width: '100%', height: '100%'}}>
                    <div style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center'
                    }}>
                      <div className={classNames.cardText}>
                        {/* <h2 style={{marginBottom: '5px', fontSize: '1rem'}}>Net Cashflow</h2> */}
                        <h2 style={{marginBottom: '5px', fontSize: '1rem'}}>
                        Net Cashflow
                          <Tooltip title="Net Cashflow YTD: Current Year-to-date cash received minus total cash spent.">
                            <InfoCircleOutlined style={{color: '#1D1C1C80', fontSize: '0.8rem', marginLeft: '10px'}} />
                          </Tooltip>
                        </h2>
                        {/* <p style={{color: '#1D1C1C80', fontSize: '0.75rem', opacity: !isDataAvailable(cashflowData?.net_cashflow_chart) ? 0 : 1}}>As of bookkeeping on {dayjs(cashflowData?.net_cashflow_chart_last_updated).format('DD MMMM, YYYY')}</p> */}
                      </div>
                      <div className={classNames.cardText} style={{alignItems: "flex-end", opacity: !isDataAvailable(cashflowData?.net_cashflow_chart) ? 0 : 1}}>
                        <p style={{fontSize: '1.8rem'}}><sup style={{top: '-0.4em'}}>$</sup>{cashflowData?.net_cashflow?.toLocaleString('en-US', {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2
                        })}</p>
                        {/* <p style={{color: '#313D44'}}>{parseFloat(cashflowData?.net_cashflow_improvement) < 0 ? <Fall /> : <Rise />} <span style={{color: parseFloat(cashflowData?.net_cashflow_improvement) < 0 ? "#99161680" : '#2E9A53', fontWeight: 'bold'}}>{convertToAbsolutePercentage(cashflowData?.net_cashflow_improvement)}</span> {dataRangeAvgMode}</p> */}
                        <h3 style={{color: '#313D44', fontSize: '0.8rem'}}>
                          YTD
                          <Tooltip title="Net Cashflow YTD: Current Year-to-date cash received minus total cash spent.">
                            <InfoCircleOutlined style={{color: '#1D1C1C80', fontSize: '0.8rem', marginLeft: '10px'}} />
                          </Tooltip>
                        </h3>
                      </div>
                    </div>
                    <div style={{margin: '3rem 0 1rem 0', display: 'flex', flexDirection: 'column', width: '99%', opacity: !isDataAvailable(cashflowData?.net_cashflow_chart) ? 0 : 1}} className={classNames.chartContainer}>
                      <Bar data={chartDataCashflow} options={stackedBarChartOptions} />
                    </div>
                  </div>
                </div>

                <div className={classNames.card} style={{position: 'relative'}}>
                  {getCashflow.isLoading && (
                    <div className={classNames.noDataOverlay} style={{fontSize: '1vw', alignItems: 'center', paddingBottom: '15px', filter: 'blur(2px)', background: '#ffffffbf', zIndex: '1000'}}>
                      <Spin />
                    </div>
                  )}
                  {!isDataAvailable(cashflowData?.opening_vs_closing_balance) && (
                    <div className={classNames.noDataOverlay}>
                      No data available yet! <br />
                      We're currently reconciling your books. <br />
                      The data will be displayed here soon.
                    </div>
                  )}
                  <div style={{width: '100%', height: '100%'}}>
                    <div style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center'
                    }}>
                      <div className={classNames.cardText}>
                        <h2 style={{marginBottom: '5px', fontSize: '1rem'}}>Closing Balance</h2>
                        {/* <p style={{color: '#1D1C1C80', fontSize: '0.75rem', opacity: !isDataAvailable(cashflowData?.opening_vs_closing_balance) ? 0 : 1}}>As of bookkeeping on {dayjs(cashflowData?.net_cashflow_chart_last_updated).format('DD MMMM, YYYY')}</p> */}
                      </div>
                      <div className={classNames.cardText}>
                        {/* <p style={{fontSize: '1.8rem'}}>${cashflowData?.free_cashflow && (Object.values(cashflowData.free_cashflow).pop().toLocaleString())}</p> */}
                        {/* <p style={{color: '#313D44'}}>{parseFloat(cashflowData?.free_cashflow_balance_improvement) < 0 ? <Fall /> : <Rise />} {cashflowData?.free_cashflow_balance_improvement} vs last month</p> */}
                      </div>
                    </div>
                    <div style={{margin: '3rem 0 1rem 0', display: 'flex', flexDirection: 'column', width: '99%', opacity: !isDataAvailable(cashflowData?.opening_vs_closing_balance) ? 0 : 1}} className={classNames.chartContainer}>
                      <Bar data={chartDataBalance} options={groupedBarChartOptions} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </>
      )}
    </div>
  );
};

export default CashFlow;
