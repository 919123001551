import endpoints from "../../../endpoints";

export default async function uploadMultipleFiles(data){
    let env = process.env.REACT_APP_ENV?.trim();
    const secureLocalStore = window.localStorage || window.sessionStorage;

    const formData = new FormData();
    // Loop through each file and append it to the form data
    console.log(data.files);
    Object.values(data.files).forEach((file) => {
        formData.append('files', file);
    });

    // Construct the URL with query parameters
    const queryParams = new URLSearchParams({
        business_id: data.business_id,
        otterz_id: data.otterz_id,
        parent_folder_id: data.parent_folder_id,
    }).toString();
    
    const baseURL = endpoints.SERVICES.DASHBOARD.DOCUMENTS_VAULT.UPLOAD_MULTIPLE_FILES[env];
    const accessToken = secureLocalStore.getItem('accessToken');

    return await(
        await fetch(`${baseURL}/?${queryParams}`, {
            method: "POST",
            headers: {
                'Authorization': `Bearer ${accessToken}`
            },
            body: formData
        })
    ).json();
}
