import endpoints from "../../../endpoints";

export default async function clearNyraChat(data){
    let env = process.env.REACT_APP_ENV?.trim();
    

    const baseURL = endpoints.SERVICES.DASHBOARD.CHATKITTY.DELETE_NYRA_CHAT[env];
    const secureLocalStore = window.localStorage || window.sessionStorage;

    const accessToken = secureLocalStore.getItem('accessToken');

    return await(
        await fetch(`${baseURL}/${data?.business_id}/${data?.channel_id}`, {
            method: "DELETE",
            headers: {
                'Authorization': `Bearer ${accessToken}`,
            }
        })
    ).json();
}
