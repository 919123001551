import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Button, Card, Col, Row, Spin, Steps, Tooltip } from 'antd';
import dayjs from 'dayjs';
import { Bar } from 'react-chartjs-2';
import { useNavigate } from 'react-router-dom';
import { gsap } from 'gsap';
import { ROUTES } from '../../constants';
import api from '../../API';
import classNames from './LandingPage.module.scss';
import { CTAButton, Message, Modal } from '../../common';
import { useAccount, useBusinessStatus, useFilter } from '../../../store/StoreProvider';
import AddAccountsModal from './OnboardModals/AddAccountsModal';
import SubscriptionModal from './OnboardModals/SubscriptionModal';
import TermsAndConditionModal from './OnboardModals/TermsAndConditionModal';
import { useMutation } from 'react-query';
import Bugsnag from '@bugsnag/js';
import { InfoCircleFilled, InfoCircleOutlined } from '@ant-design/icons';
import moment from 'moment';
import BeigeLegend from '../../OtterzCo-Pilot/icons/BeigeLegend';
import GrayLegend from '../../OtterzCo-Pilot/icons/GrayLegend';
// import TreasureLogo from './Assets/treasure.png';

// Define the onboarding steps in order
const onboardingStepsOrder = [
  { key: 'acceptTerms', title: 'Review and accept the Otterz Terms of Service' },
  { key: 'subscribePlan', title: 'Choose a subscription plan based on your needs' },
  { key: 'connectPlaidQuickbooks', title: 'Connect your bank and QuickBooks accounts' },
];
const { Step } = Steps;

const LandingPage = () => {
  const navigate = useNavigate();
  // const secureLocalStore = window.localStorage || window.secureLocalStore;
  const [cashChartData, setCashChartData] = useState(null);
  const [cardChartData, setCardChartData] = useState(null);
  const [totalCash, setTotalCash] = useState(0);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isPaymentAcceptanceModalVisible, setIsPaymentAcceptanceModalVisible] = useState(false);
  const [totalCard, setTotalCard] = useState(0);
  const [loading, setLoading] = useState(true); // Add loading state
  const [accountLoading, accountSetLoading] = useState(true); // Add loading state
  const [data, setData] = useState(null);
  const [disconnectedAccounts, setDisconnectedAccounts] = useState({ plaid: [], quickbooks: [] });
  const [actionsPendingCount, setActionsPendingCount] = useState({
    bookkeeping: 0,
    tax: 0,
  });

  const [isAddAccountsModalVisible, setIsAddAccountsModalVisible] = useState(false);
  const [isSubscriptionModalVisible, setIsSubscriptionModalVisible] = useState(false);
  const [isTermsAndConditionModalVisible, setIsTermsAndConditionModalVisible] = useState(false);

  const {selectedBusiness} = useAccount();
  const { businessStatus, setBusinessStatus } = useBusinessStatus();

  const stepsCompleted = businessStatus?.dashboard_status?.stepsCompleted;

  const combinedStepsCompleted = useMemo(() => ({
    ...stepsCompleted,
    connectPlaidQuickbooks: stepsCompleted?.connectPlaid || stepsCompleted?.connectQuickbooks
  }), [stepsCompleted]);
  
  // Determine if all steps are completed
  const isDashboardOnboarded = useMemo(() => {
    // Check if the states are complete, for qb and plaid, if either is true then return true
    return combinedStepsCompleted?.acceptTerms && combinedStepsCompleted?.subscribePlan && (combinedStepsCompleted?.connectPlaid || combinedStepsCompleted?.connectQuickbooks);
  }, [combinedStepsCompleted]);
  

  const stepsOrder = [
    { key: 'acceptTerms', title: 'Accept Terms of Service' },
    { key: 'subscribePlan', title: 'Choose Subscription Plan' },
    { key: 'connectPlaidQuickbooks', title: 'Connect QuickBooks and Plaid' },
  ];

  const getStepStatus = (step) => {
    if (combinedStepsCompleted[step.key]) {
      switch (step.key) {
        case 'acceptTerms':
          return "Accepted Terms of Service";
        case 'subscribePlan':
          return "Subscription Active";
        case 'connectPlaidQuickbooks':
          return "Accounts Connected";
        default:
          return "Completed"; // Or you can return a default message here
      }
    } else {
      return getButtonLabel(step.key);
    }
  };

  // Compute the current step based on combined stepsCompleted
  const currentStep = useMemo(() => {
    for (let i = 0; i < onboardingStepsOrder.length; i++) {
      const stepKey = onboardingStepsOrder[i].key;
      if (!combinedStepsCompleted[stepKey]) {
        return i; // Zero-based index
      }
    }
    return onboardingStepsOrder.length; // All steps completed
  }, [combinedStepsCompleted]);

  // Determine if a step is enabled based on previous steps
  const isStepEnabled = (stepIndex) => {
    if (stepIndex === 0) return true; // First step is always enabled
    // All previous steps must be completed to enable this step
    for (let i = 0; i < stepIndex; i++) {
      const stepKey = onboardingStepsOrder[i].key;
      if (!combinedStepsCompleted[stepKey]) return false;
    }
    return true;
  };

  const getBookkeepingQueriesAndCount = useMutation(
    async (data) => {
      const response = await api.Services.Dashboard.ClientQueries.getBookkeepingQueriesAndCount(data);
      if (!response.status) {
        Message({ type: "error", content: response.errors[0].message });
        return;
      }
  
      if (response.data && response.data) {
        const transactions = response.data;
        console.log("Query transactions", transactions);
        setActionsPendingCount((prevCount) => ({
          ...prevCount,
          bookkeeping: transactions.count,
        }));
      }
    },
    {
      onError: (error) => {
        Bugsnag.notify(
          "Error in fetching businesses: getBookkeepingQueriesAndCount",
          error,
          error.message
        );
        Message({ type: "error", content: error.message });
      },
    }
  );

  const getTaxQueries = useMutation(
    async (data) => {
      const response = await api.Services.Dashboard.ClientQueries.getTaxQueries(data);
      if (!response.status) {
        Message({ type: "error", content: response.errors[0].message });
        return;
      }
  
      if (response && response.data) {
        console.log("Tax Query transactions", response.data);
        setActionsPendingCount((prevCount) => ({
          ...prevCount,
          tax: response.data.length,
        }));
      }
    },
    {
      onError: (error) => {
        Bugsnag.notify(
          "Error in fetching businesses: getTaxQueries",
          error,
          error.message
        );
        Message({ type: "error", content: error.message });
      },
    }
  );

  useEffect(() => {
    if (selectedBusiness.business_id) {
      // Fetch the list of bookkeeping queries and count
      getBookkeepingQueriesAndCount.mutate(selectedBusiness.business_id);

      // Fetch the list of tax queries
      getTaxQueries.mutate(selectedBusiness.business_id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedBusiness]);

  // get integration status by making api call to checkConnectionStatus
  const getIntegrationStatus = useMutation(data => api.Services.Onboarding.checkConnectionStatus(data), {
    onSuccess: (data) => {
      if (!data.status) {
        Message({ type: "error", content: data.errors[0].message });
        return;
      }
      setDisconnectedAccounts(data.data);
    },
    onError: (error) => {
      // Message({ type: "error", content: error.message });
      Bugsnag.notify("Error fetching integration status: ", error);  
    },
  });

  // Fetch businesses and initialize Plaid link token
  const getBusinessStatus = useMutation(data => api.Services.Onboarding.getBusinessStatus(data), {
    onSuccess: (data) => {
      if (!data.status) {
        Message({ type: "error", content: data.errors[0].message });
        return;
      }
      console.log("Business Status: ", data.data);
      setBusinessStatus(data.data);

      const { stepsCompleted } = data.data.dashboard_status;
      // If all steps are completed, navigate to the dashboard
      if (stepsCompleted.acceptTerms && stepsCompleted.subscribePlan && (stepsCompleted.connectPlaid || stepsCompleted.connectQuickbooks)) {
        Message({ type: "success", content: "Onboarding steps completed successfully" });
      }
      
    },
    onError: (error) => {
      // Message({ type: "error", content: error.message });
      Bugsnag.notify("Error fetching business status: ", error, error.message);
    },
  });

  const updatedStatus = async () => {
    // const status = await api.Services.Onboarding.getBusinessStatus({business_id: selectedBusiness?.business_id});
    getBusinessStatus.mutate({business_id: selectedBusiness?.business_id});
  };

  const fetchAccounts = async () => {
    try {
      const accountsResponse = await api.Services.Onboarding.getAccountsPlaid(selectedBusiness?.business_id);
      console.log("Accounts:", accountsResponse);
  
      if (accountsResponse.status) {
        const accountsData = accountsResponse.data.accounts;
        const transformedAccounts = accountsData.flat()
          .filter(account => account.type === 'depository') // Filter only depository accounts
          .map(account => ({
            account_id: account.account_id,
            account: `**** **** **** ${account.mask}`,
            bank: account.official_name || account.name,
            balance: account.balances.current,
          }));
       
        console.log("Transformed Accounts:", transformedAccounts);
        // accountSetLoading(false);
      } else {
        // Message({ type: "error", content: "Failed to fetch accounts" });
        accountSetLoading(false);
      }
    } catch (error) {
      // Message({ type: "error", content: "Failed to fetch accounts" });
      accountSetLoading(false);
    } finally {
      accountSetLoading(false); // Update loading state after fetching accounts
    }
  };

  useEffect(() => {
    const getWeekNumber = (dateStr) => {
      const date = new Date(dateStr);
      const startOfYear = new Date(date.getFullYear(), 0, 1);
      const pastDaysOfYear = (date - startOfYear) / 86400000;
      return Math.ceil((pastDaysOfYear + startOfYear.getDay() + 1) / 7);
    };

    const convertLabel = (label) => {
      const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
  
      if (/^\d{4}-\d{2}$/.test(label)) {
        const [year, month] = label.split("-");
        return `${monthNames[parseInt(month) - 1]}'${year.slice(2)}`;
      }
  
      if (/^\d{4}-\d{2}-\d{2}$/.test(label)) {
        const weekNumber = getWeekNumber(label);
        return `W${weekNumber}`;
      }
  
      return label; // Return the label as is if it doesn't match any format
    };

    const fetchTransactions = async () => {
      try {
        const balances = await api.Services.Onboarding.getBalances(selectedBusiness?.business_id);

        if (balances.status) {
          // Process transaction data to extract necessary information for charts
          const cashData = balances.data.cash_historical_balance.map(entry => ({
            month: entry.month,
            balance: entry.end_of_month_balance
          }));
          const cardData = balances.data.card_historical_balance.map(entry => ({
            month: entry.month,
            balance: entry.end_of_month_balance
          }));

          // Convert data to chart dataset format
          const cashChartData = {
            labels: cashData.map(entry => entry.month).map(convertLabel),
            datasets: [
              {
                label: 'Cash Balance',
                data: cashData.map(entry => entry.balance.toFixed(2)),
                backgroundColor: '#E1BB80',
                borderRadius: 10,
                barPercentage: 0.5,
              },
            ],
          };

          const cardChartData = {
            labels: cardData.map(entry => entry.month).map(convertLabel),
            datasets: [
              {
                label: 'Credit Spend',
                data: cardData.map(entry => entry.balance.toFixed(2)),
                backgroundColor: '#7B6B43',
                borderRadius: 10,
                barPercentage: 0.5,
              },
            ],
          };

          setCashChartData(cashChartData);
          setCardChartData(cardChartData);

          console.log("Balances:", cashChartData, cardChartData, balances);
          

          setTotalCash(balances.data.current_cash_balance);
          setTotalCard(balances.data.current_card_balance);
        }
      } catch (error) {
        console.error('Error fetching transactions:', error);
      } finally {
        setLoading(false); // Set loading to false after data processing is complete
      }
    };

    if (selectedBusiness.business_id) {
      setLoading(true);
      getTaxOnboardingStatus.mutateAsync({ business_id: selectedBusiness?.business_id });
      getIntegrationStatus.mutate(selectedBusiness.business_id);
      accountSetLoading(true);
      fetchTransactions();
      fetchAccounts()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedBusiness, isDashboardOnboarded]);

  const [taxOnboarded, setTaxOnboarded] = useState("TAX_ONBOARDING_COMPLETE");

  // Mutation to fetch tax onboarding status
  const getTaxOnboardingStatus = useMutation(data => api.Services.Dashboard.TaxFilling.getTaxOnboardingStatus(data), {
    onSuccess: (data) => {
      if (!data.status) {
        setTaxOnboarded("ONBOARDING_START");
        // Message({ type: "error", content: data.errors[0].message });
      }

      if (data && data.data) {
        console.log("status", data.data.tax_filling_status);
        setTaxOnboarded(data.data.tax_filling_status);
      }
    },
    onError: (error) => {
      setTaxOnboarded("ONBOARDING_START");
      console.error("Error fetching tax onboarding status: ", error);
      // Message({ type: "error", content: error.message });
    },
  });

  const flashIntervalRef = useRef(null);

  useEffect(() => {
    if ((taxOnboarded !== 'TAX_ONBOARDING_COMPLETE' && (new Date().getMonth() >= 10 || new Date().getMonth() <= 3)) || (actionsPendingCount.tax + actionsPendingCount.bookkeeping) > 0) {
      gsap.to(flashIntervalRef.current, { 
        duration: 1,
        color: '#C85103',
        repeat: -1,
        yoyo: true,
        ease: "power1.inOut"
      });
    } else {
      gsap.to(flashIntervalRef.current, {
        duration: 0,
        color: 'transparent',
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [taxOnboarded, actionsPendingCount]);

  const chartOptions = {
    plugins: {
      legend: {
        display: false,
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
      },
      y: {
        grid: {
          drawBorder: false,
          display: true,
        },
        ticks: {
          font: {
            size: 16,
          },
          autoSkip: false,
          maxTicksLimit: 8,
          // stepSize: 4000
          callback: function (value, index, values) {
            return `$${value.toLocaleString()}`;
          },
        },
      },
    },
  };

  const [ ,setDateRangeAvgMode] = useState("MoM");
  const {
    insightFilter,
    setInsightFilter,
    dateRange,
    setDateRange,
    // showCustomDatePicker,
    // setShowCustomDatePicker,
    applyClicked,
    // setApplyClicked,
    // inputDateRange,
    // setInputDataRange
  } = useFilter();

  // Helper function to check if data is available
  // const isDataAvailable = (data) => {
  //   return data !== null && data !== undefined && Object.keys(data).length > 0;
  // };

  const getProfitLoss = useMutation(data => api.Services.Dashboard.BusinessOverview.v2.getProfitLossV2(data), {
    onSuccess: (responseData) => {
      if (!responseData.status) {
        Message({ type: "error", content: responseData.errors[0]?.message });
        return;
      }

      setData(responseData.data);
      setLoading(false);
    },
    onError: (error) => {
      Message({ type: "error", content: error.message });
      setLoading(false);
    },
  });

  useEffect(() => {
    if (selectedBusiness.business_id) {
      let filterValue = {};
      if (insightFilter === 'week') {
        setDateRangeAvgMode('WoW')
      } else if (insightFilter === "year") {
        setDateRangeAvgMode('YoY')
      } else {
        setDateRangeAvgMode('MoM')
      }
      if (insightFilter === 'custom') {
        const lastMonth = moment().subtract(1, 'months').format("YYYY-MM");

        filterValue = {
          filter_key: "custom",
          filter_value: {
            start_date: lastMonth,
            end_date: lastMonth
          }
        };
      } else {
        if (insightFilter === 'custom') {
          // filterValue = {
          //   filter_key: "month",
          //   filter_value: {}
          // };
        } else {
          setDateRange(null);
          filterValue = {
            filter_key: insightFilter,
            filter_value: {}
          };
        }
      }
  
      // Fetch data using the selected filter value

      if (Object.keys(filterValue).length > 0) {
        getProfitLoss.mutateAsync({
          business_id: selectedBusiness.business_id,
          body: filterValue,
        });
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedBusiness, dateRange, insightFilter, applyClicked]);

  const getWeekNumber = (dateStr) => {
    const date = new Date(dateStr);
    const startOfYear = new Date(date.getFullYear(), 0, 1);
    const pastDaysOfYear = (date - startOfYear) / 86400000;
    return Math.ceil((pastDaysOfYear + startOfYear.getDay() + 1) / 7);
  };

  const convertLabel = (label) => {
    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

    if (/^\d{4}-\d{2}$/.test(label)) {
      const [year, month] = label.split("-");
      return `${monthNames[parseInt(month) - 1]}'${year.slice(2)}`;
    }

    if (/^\d{4}-\d{2}-\d{2}$/.test(label)) {
      const weekNumber = getWeekNumber(label);
      return `W${weekNumber}`;
    }

    return label; // Return the label as is if it doesn't match any format
  };

  const getRevenueValue = () => {
    if (insightFilter === 'custom') {
      const lastMonthDate = new Date();
      lastMonthDate.setMonth(lastMonthDate.getMonth() - 1);
      const formattedLastMonth = `${lastMonthDate.getFullYear()}-${(lastMonthDate.getMonth() + 1).toString().padStart(2, '0')}`;
      return parseFloat(data?.revenue_chart[formattedLastMonth]) || 0;
    } else if (insightFilter === 'month') {
      return parseFloat(data?.average_revenue) || 0;
    } else if (insightFilter === 'year') {
      return parseFloat(data?.revenue) || 0;
    }
    return 0;
  };
  const getExpenseValue = () => {
    if (insightFilter === 'custom') {
      const lastMonthDate = new Date();
      lastMonthDate.setMonth(lastMonthDate.getMonth() - 1);
      const formattedLastMonth = `${lastMonthDate.getFullYear()}-${(lastMonthDate.getMonth() + 1).toString().padStart(2, '0')}`;
      
      const operatingExpenses = parseFloat(data?.total_expenses_chart[formattedLastMonth]?.operating_expenses) || 0;
      const costOfSales = parseFloat(data?.total_expenses_chart[formattedLastMonth]?.cost_of_sales) || 0;
      
      return (operatingExpenses + costOfSales)//.toFixed(2);
    } else if (insightFilter === 'month') {
      return data?.average_burn|| '0.00';
    } else if (insightFilter === 'year') {
      return data?.total_expenses || '0.00';
    }
    return '0.00';
  };
  const getProfitAndLossValue = () => {
    if (insightFilter === 'custom') {
      const lastMonthDate = new Date();
      lastMonthDate.setMonth(lastMonthDate.getMonth() - 1);
      const formattedLastMonth = `${lastMonthDate.getFullYear()}-${(lastMonthDate.getMonth() + 1).toString().padStart(2, '0')}`;
      return parseFloat(data?.profit_and_loss_chart[formattedLastMonth]) || 0;
    } else if (insightFilter === 'month') {
      return data?.average_profit_loss || 0;
    } else if (insightFilter === 'year') {
      return data?.profit_and_loss || 0;
    }
    return 0;
  };

  const stackedBarChartData = {
    labels: Object.keys(data?.total_expenses_chart || {}).map(convertLabel),
    datasets: [
      {
        label: 'Operating Expenses',
        data: Object.values(data?.total_expenses_chart || {}).map((values) => values.operating_expenses),
        backgroundColor: "#E1BB80", // Set custom color for operating expenses
        borderRadius: 5,
      },
      {
        label: 'Cost of Sales',
        data: Object.values(data?.total_expenses_chart || {}).map((values) => values.cost_of_sales),
        backgroundColor: "#BFB1A1D6", // Set custom color for cost of sales
        borderRadius: 5,
      },
    ],
  };

  const footer = (tooltipItems) => {
    let sum = 0;
    tooltipItems.forEach(function(tooltipItem) {
      sum = tooltipItem?.parsed?._stacks?.y?._visualValues[0] + tooltipItem?.parsed?._stacks?.y?._visualValues[1];
    });

    return 'Total Expense: ' + sum?.toLocaleString('en-US', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    });
  };

  const totalExpenseOptions = {
    responsive: true,
    scales: {
      x: {
        stacked: true,
        grid: {
          display: false,
        },
        // ticks: {
        //   font: {
        //     size: 16,
        //   },
        // },
      },
      y: {
        stacked: true,
        suggestedMin: 0,
        grid: {
          display: true,
        },
        ticks: {
          font: {
            size: 15,
          },
          autoSkip: false,
          maxTicksLimit: 8,
          callback: function (value, index, values) {
            return `$${value.toLocaleString('en-US', {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2
            })}`;
          },
          stepSize: 2000
        },
      }
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: true,
        callbacks: {
          footer: footer,
        }
      },
    },
  }

  // Helper function to check if data is available
  const isDataAvailable = (data) => {
    return data !== null && data !== undefined && Object.keys(data).length > 0;
  };
  
  return (
    <div className={classNames.container} style={{height: '100%'}}>

      {isDashboardOnboarded ? (
        <section className={classNames.innerContent}>
          <div style={{
            display: 'flex',
            width: '100%',
            justifyContent: 'space-between'
          }}>
            <div>
              <h2 className={classNames.heading}>Business Overview</h2>
              <p style={{color: '#1D1C1C80', fontSize: '0.75rem', opacity: !data?.average_profit_loss ? 0 : 1}}>As of bookkeeping on {dayjs(data?.revenue_last_updated).format('DD MMMM, YYYY')}</p>
            </div>
            <div style={{
              display: 'flex',
              gap: '5px',
              padding: '10px 1vw 0vw'
            }}>
              {['YTD', 'Average', 'Last Month'].map((filter, index) => (
                <Button 
                  key={index}
                  type='link' 
                  style={{ 
                    backgroundColor: (filter === 'YTD' && insightFilter === 'year') || (filter === 'Average' && insightFilter === 'month') || (filter === 'Last Month' && insightFilter === 'custom') ? '#616161' : 'white', 
                    color: (filter === 'YTD' && insightFilter === 'year') || (filter === 'Average' && insightFilter === 'month') || (filter === 'Last Month' && insightFilter === 'custom') ? 'white' : 'black',
                    borderRadius: '20px',
                    fontWeight: 'bold',
                    transition: 'background-color 0.3s, color 0.3s', // Add transition for smooth hover effect
                  }} 
                  onClick={() => {
                    filter === 'Last Month' ? setInsightFilter('custom'): setInsightFilter(filter === 'Average' ? 'month' : 'year');
                    // setInsightFilter(filter === 'Average' ? 'month' : 'year');
                  }}
                >
                  {filter}
                </Button>
              ))}
            </div>
          </div>

          {(disconnectedAccounts.plaid.length > 0 || disconnectedAccounts.quickbooks.length > 0) && <Row gutter={[16, 16]}>
            <Col span={24}>
                <Card style={{ boxShadow: '0px 4px 4px 0px #0000000D', border: '1px solid #EEF1F1', cursor: 'pointer', margin: '0 0 1rem 0'}}>
                  <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                    <div>
                      <p style={{
                        margin: '0 0 10px 0',
                        fontWeight: 'bold'
                      }}>For security purposes, please reauthorize certain accounts by reconnecting them <span>{<InfoCircleFilled style={{color: '#C85103', marginLeft: '10px'}} />} </span></p>
                      <p style={{color: '#82898F'}}>Dashboards do not reflect data from these accounts. </p>
                    </div>
                    <CTAButton style={{
                        background: 'linear-gradient(92.72deg, #CF830E 8.37%, #C85103 94.73%)',
                        // marginTop: '10px',
                        width: '300px',
                        border: 'none'
                      }} onClick={() => {navigate('settings/integrations')}}>Reauthorize Accounts</CTAButton>
                  </div>
                </Card>
            </Col>
          </Row>          
          }

          <div className={classNames.card} style={{
              border: 'none',
              boxShadow: 'none',
              padding: '0',
              margin: '0',
              // filter: Object.entries(cashflowData).length === 0 ? "blur(5px)" : null
          }}>
            <div className={classNames.gridCashflow} style={{overflowX:'auto'}}>
              {/* First Row */}
              <div className={classNames.card}>
                <div  style={{
                  // display: 'flex',
                  // justifyContent: 'space-between',
                  // alignItems: 'center',
                  position: 'relative'
                }}>
                  {getProfitLoss.isLoading && (
                    <div className={classNames.noDataOverlay} style={{fontSize: '1vw', alignItems: 'center', paddingBottom: '15px', filter: 'blur(2px)', background: '#ffffffbf', zIndex: '1000'}}>
                      <Spin />
                    </div>
                  )}
                  {!data?.average_revenue && (
                    <div className={classNames.noDataOverlay} style={{fontSize: '0.9rem', alignItems: 'end', paddingBottom: '15px'}}>
                      No data available yet! <br />
                      {/* We're currently reconciling your books. <br />
                      The data will be displayed here soon. */}
                    </div>
                  )}
                  <div className={classNames.cardText}>
                    {/* <h3 style={{fontSize: '1rem'}}>30 days Cash In <span style={{color: 'gray', fontSize: '0.8rem'}}>(rolling)</span></h3> */}
                    <h3 style={{fontSize: '1rem'}}>
                      Revenue
                      {/* <Tooltip title="Average Cash In (Per Month): Average cash received during a certain period.">
                        <InfoCircleOutlined style={{color: '#1D1C1C80', fontSize: '0.8rem', marginLeft: '10px'}} />
                      </Tooltip> */}
                    </h3>
                    {/* <p style={{color: '#1D1C1C80', fontSize: '0.75rem', opacity: !data?.average_revenue ? 0 : 1}}>As of bookkeeping on {dayjs(data?.revenue_last_updated).format('DD MMMM, YYYY')}</p> */}
                  </div>
                  <div className={classNames.cardText} style={{opacity: !data?.average_revenue ? 0 : 1, alignItems: 'flex-start'}}>
                    <p style={{fontSize: '1.8rem'}}><sup style={{top: '-0.4em'}}>$</sup>{data && getRevenueValue().toLocaleString('en-US', {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2
                    })}</p>
                    {/* <p style={{color: '#313D44'}}>{parseFloat(cashflowData && cashflowData['30_day_cash_in_improvement']) < 0 ? <Fall /> : <Rise />} <span style={{color: parseFloat(cashflowData['30_day_cash_in_improvement']) < 0 ? "#99161680" : '#2E9A53', fontWeight: 'bold'}}>{cashflowData && convertToAbsolutePercentage(cashflowData['30_day_cash_in_improvement'])}</span> vs last month</p> */}
                    {/* <p style={{color: '#313D44', fontSize: '0.8rem'}}>Per Month</p> */}
                  </div>
                </div>
              </div>
              
              <div className={classNames.card}>
                <div style={{
                  // display: 'flex',
                  // justifyContent: 'space-between',
                  // alignItems: 'center',
                  position: 'relative'
                }}>
                  {getProfitLoss.isLoading && (
                    <div className={classNames.noDataOverlay} style={{fontSize: '1vw', alignItems: 'center', paddingBottom: '15px', filter: 'blur(2px)', background: '#ffffffbf', zIndex: '1000'}}>
                      <Spin />
                    </div>
                  )}
                  {!data?.average_burn && (
                    <div className={classNames.noDataOverlay} style={{fontSize: '0.9rem', alignItems: 'end', paddingBottom: '15px'}}>
                      No data available yet! <br />
                      {/* We're currently reconciling your books. <br />
                      The data will be displayed here soon. */}
                    </div>
                  )}
                  <div className={classNames.cardText}>
                    {/* <h3 style={{fontSize: '1rem'}}>30 days Cash Burn <span style={{color: 'gray', fontSize: '0.8rem'}}>(rolling)</span></h3> */}
                    <h3 style={{fontSize: '1rem'}}>
                      Expenses
                      {/* <Tooltip title="Average Cash Burn: Average cash spent during a certain period.">
                        <InfoCircleOutlined style={{color: '#1D1C1C80', fontSize: '0.8rem', marginLeft: '10px'}} />
                      </Tooltip> */}
                    </h3>
                    {/* <p style={{color: '#1D1C1C80', fontSize: '0.75rem', opacity: !data?.average_burn ? 0 : 1}}>As of bookkeeping on {dayjs(data?.revenue_last_updated).format('DD MMMM, YYYY')}</p> */}
                  </div>
                  <div className={classNames.cardText} style={{opacity: !data?.average_burn ? 0 : 1, alignItems: 'flex-start'}}>
                    <p style={{fontSize: '1.8rem'}}><sup style={{top: '-0.4em'}}>$</sup>{data && getExpenseValue().toLocaleString('en-US', {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2
                    })}</p>
                    {/* <p style={{color: '#313D44'}}>{parseFloat(cashflowData && cashflowData['30_day_cash_burn_improvement']) < 0 ? <Fall /> : <Rise />} <span style={{color: parseFloat(cashflowData['30_day_cash_burn_improvement']) < 0 ? "#99161680" : '#2E9A53', fontWeight: 'bold'}}>{cashflowData && convertToAbsolutePercentage(cashflowData['30_day_cash_burn_improvement'])}</span> vs last month</p> */}
                    {/* <p style={{color: '#313D44', fontSize: '0.8rem'}}>Per Month</p> */}
                  </div>
                </div>
                {/* <div style={{
                  display: 'flex',
                  gap: '5px',
                  padding: '10px 1vw 0vw'
                }}>
                  {['YTD', 'Average', 'Last Month'].map((filter, index) => (
                    <Button 
                      key={index}
                      type='link' 
                      style={{ 
                        backgroundColor: (filter === 'YTD' && insightFilter === 'year') || (filter === 'Average' && insightFilter === 'month') || (filter === 'Last Month' && insightFilter === 'custom') ? '#616161' : 'white', 
                        color: (filter === 'YTD' && insightFilter === 'year') || (filter === 'Average' && insightFilter === 'month') || (filter === 'Last Month' && insightFilter === 'custom') ? 'white' : 'black',
                        borderRadius: '20px',
                        fontWeight: 'bold',
                        transition: 'background-color 0.3s, color 0.3s', // Add transition for smooth hover effect
                      }} 
                      onClick={() => {
                        filter === 'Last Month' ? setInsightFilter('custom'): setInsightFilter(filter === 'Average' ? 'month' : 'year');
                        // setInsightFilter(filter === 'Average' ? 'month' : 'year');
                      }}
                    >
                      {filter}
                    </Button>
                  ))}
                </div> */}
              </div>
              
              <div className={classNames.card}>
                <div style={{
                  // display: 'flex',
                  // justifyContent: 'space-between',
                  // alignItems: 'center',
                  position: 'relative'
                }}>
                  {getProfitLoss.isLoading && (
                    <div className={classNames.noDataOverlay} style={{fontSize: '1vw', alignItems: 'center', paddingBottom: '15px', filter: 'blur(2px)', background: '#ffffffbf', zIndex: '1000'}}>
                      <Spin />
                    </div>
                  )}
                  {!data?.average_profit_loss && (
                    <div className={classNames.noDataOverlay} style={{fontSize: '0.9rem', alignItems: 'end', paddingBottom: '15px'}}>
                      No data available yet! <br />
                      {/* We're currently reconciling your books. <br />
                      The data will be displayed here soon. */}
                    </div>
                  )}
                  <div className={classNames.cardText}>
                    {/* <h3 style={{fontSize: '1rem'}}>Cash on Hand</h3> */}
                    <h3 style={{fontSize: '1rem'}}>
                      Profit/Loss
                      {/* <Tooltip title="Average Cash Balance: Average cash balance during a certain period.">
                        <InfoCircleOutlined style={{color: '#1D1C1C80', fontSize: '0.8rem', marginLeft: '10px'}} />
                      </Tooltip> */}
                    </h3>
                    {/* <p style={{color: '#1D1C1C80', fontSize: '0.75rem', opacity: !data?.average_profit_loss ? 0 : 1}}>As of bookkeeping on {dayjs(data?.revenue_last_updated).format('DD MMMM, YYYY')}</p> */}
                  </div>
                  <div className={classNames.cardText} style={{opacity: !data?.average_profit_loss ? 0 : 1, alignItems: 'flex-start'}}>
                    <p style={{fontSize: '1.8rem'}}><sup style={{top: '-0.4em'}}>$</sup>{data && getProfitAndLossValue().toLocaleString('en-US', {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2
                    })}</p>
                    {/* <p style={{color: '#313D44'}}>{parseFloat(cashflowData?.cash_on_hand_improvement) < 0 ? <Fall /> : <Rise />} <span style={{color: parseFloat(cashflowData?.cash_on_hand_improvement) < 0 ? "#99161680" : '#2E9A53', fontWeight: 'bold'}}>{convertToAbsolutePercentage(cashflowData?.cash_on_hand_improvement)}</span> vs last month</p> */}
                    {/* <p style={{color: '#313D44', fontSize: '0.8rem'}}>Per Month</p> */}
                  </div>
                </div>
                {/* <div style={{
                    display: 'flex',
                    gap: '5px',
                    padding: '10px 1vw 0vw'
                  }}>
                    {['YTD', 'Average', 'Last Month'].map((filter, index) => (
                      <Button 
                        key={index}
                        type='link' 
                        style={{ 
                          backgroundColor: (filter === 'YTD' && insightFilter === 'year') || (filter === 'Average' && insightFilter === 'month') || (filter === 'Last Month' && insightFilter === 'custom') ? '#616161' : 'white', 
                          color: (filter === 'YTD' && insightFilter === 'year') || (filter === 'Average' && insightFilter === 'month') || (filter === 'Last Month' && insightFilter === 'custom') ? 'white' : 'black',
                          borderRadius: '20px',
                          fontWeight: 'bold',
                          transition: 'background-color 0.3s, color 0.3s', // Add transition for smooth hover effect
                        }} 
                        onClick={() => {
                          filter === 'Last Month' ? setInsightFilter('custom'): setInsightFilter(filter === 'Average' ? 'month' : 'year');
                          // setInsightFilter(filter === 'Average' ? 'month' : 'year');
                        }}
                      >
                        {filter}
                      </Button>
                    ))}
                  </div> */}
                </div>
            </div>
          </div>

          { loading || accountLoading ? ( // Render spinner if loading
            <div className={classNames.spinnerContainer}>
              <Spin size="large" />
            </div>
          ) : (
            <Row gutter={[16, 16]}>
              <Col span={12}>
                <Card onClick={() => {
                    cashChartData.datasets[0].data.every(value => value !== "0.00") ? navigate(ROUTES.APP.CASH_BALANCE) : navigate("/app/settings/integrations")
                  }}
                  style={{ boxShadow: '0px 4px 4px 0px #0000000D', border: '1px solid #EEF1F1', cursor: 'pointer', height: '100%'}}
                >
                  <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <h3 className={classNames.heading}>Cash Balance</h3>
                    <div style={{ textAlign: 'right' }}>
                      <p style={{ marginBottom: 0, fontSize: '2rem' }}>{cashChartData && cashChartData.datasets[0].data.every(value => value !== "0.00") ? `$ ${totalCash.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}` : ''}</p>
                    </div>
                  </div>
                  {!cashChartData || cashChartData?.datasets[0]?.data.every(value => value === "0.00") ? (
                    <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                      <p style={{display: 'flex', justifyContent: 'center', alignItems: 'center', textAlign: 'center', height: '300px', width: '350px'}}>No data available yet! To get started, please connect your Bank and QuickBooks accounts in Settings &gt; Integrations.</p>
                    </div>
                  ) : (
                    <Bar data={cashChartData} options={chartOptions} />
                  )}
                  {/* {cashChartData && <Bar data={cashChartData} options={chartOptions} />} */}
                </Card>
              </Col>
              <Col span={12}>
                <Card onClick={() => {
                    cardChartData.datasets[0].data.every(value => value !== "0.00") ? navigate(ROUTES.APP.CARD_BALANCE) : navigate("/app/settings/integrations")
                  }}
                  style={{ boxShadow: '0px 4px 4px 0px #0000000D', border: '1px solid #EEF1F1', cursor: 'pointer', height: '100%' }}
                >
                  {(!(!cardChartData || cardChartData?.datasets[0]?.data.every(value => value === "0.00")) && <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <h3 className={classNames.heading}>Credit Spend</h3>
                    <div style={{ textAlign: 'right' }}>
                    <p style={{ marginBottom: 0, fontSize: '2rem' }}>{cardChartData && cardChartData.datasets[0].data.every(value => value !== "0.00") ? `$ ${totalCard.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}` : ''}</p>
                    </div>
                  </div>)}
                  
                  {!cardChartData || cardChartData?.datasets[0]?.data.every(value => value === "0.00") ? (
                    <>
                      {getProfitLoss.isLoading && (
                        <div className={classNames.noDataOverlay} style={{fontSize: '1vw', alignItems: 'center', justifyContent: 'center', paddingBottom: '15px', filter: 'blur(2px)', background: '#ffffffbf', zIndex: '1000'}}>
                          <Spin />
                        </div>
                      )}
                      {!isDataAvailable(data?.total_expenses_chart) && (
                        <div className={classNames.noDataOverlay}>
                          No data available yet! <br />
                          We're currently reconciling your books. <br />
                          The data will be displayed here soon.
                        </div>
                      )}
                      <div style={{width: '100%', height: '100%'}}>
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                          <div className={classNames.cardText}>
                            <h3 style={{fontSize: '1rem'}}>Spend</h3>
                            {/* <p style={{ color: '#1D1C1C80', fontSize: '0.75rem', opacity: !isDataAvailable(data?.total_expenses_chart) ? 0 : 1 }}>As of bookkeeping on {data?.total_expenses_last_updated ? dayjs(data.total_expenses_last_updated.split(' ')[0]).format('DD MMMM, YYYY') : ''}</p> */}
                          </div>
                          <div className={classNames.cardText} style={{alignItems: "flex-end", opacity: !isDataAvailable(data?.total_expenses_chart) ? 0 : 1}}>
                            <p style={{ fontSize: '1.8rem' }}><sup style={{top: '-0.4em'}}>$</sup>{data?.total_expenses?.toLocaleString('en-US', {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2
                            })}</p>
                            {/* <p style={{ color: '#313D44' }}>{parseFloat(data?.total_expenses_improvement) < 0 ? <Fall /> : <Rise />} <span style={{color: parseFloat(data?.total_expenses_improvement) < 0 ? "#99161680" : '#2E9A53', fontWeight: 'bold'}}>{convertToAbsolutePercentage(data?.total_expenses_improvement)}</span> {dataRangeAvgMode}</p> */}
                            {/* <h3 style={{color: '#313D44', fontSize: '0.8rem'}}>
                              YTD
                              <Tooltip title="Expense YTD: Year-to-date total expenses.">
                                <InfoCircleOutlined style={{color: '#1D1C1C80', fontSize: '0.8rem', marginLeft: '10px'}} />
                              </Tooltip>
                            </h3> */}
                          </div>
                        </div>
                        <div style={{ 
                          margin: '0.5rem 0 0 0', 
                          display: 'flex', 
                          flexDirection: 'column', 
                          opacity: !isDataAvailable(data?.total_expenses_chart) ? 0 : 1 
                        }} className={classNames.chartContainer}>
                          <Bar data={stackedBarChartData} options={totalExpenseOptions} />
                          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '60px', marginTop: '1rem' }}>
                            <div style={{ display: 'flex', alignItems: 'center', gap: '10px', fontSize: '0.9rem' }}>
                              <BeigeLegend />
                              <span style={{fontWeight: '500'}}>Operating Expenses</span>
                              <Tooltip title="Operating Expenses: The total costs required to run the business, excluding the cost of sales.">
                                <InfoCircleOutlined style={{color: '#1D1C1C80', fontSize: '0.8rem', marginLeft: '5px'}} />
                              </Tooltip>
                            </div>
                            <div style={{ display: 'flex', alignItems: 'center', gap: '10px', fontSize: '0.9rem' }}>
                              <GrayLegend />
                              <span style={{fontWeight: '500'}}>Cost of Sales</span>
                              <Tooltip title="Cost of Sales: The direct costs associated with producing the goods or services sold.">
                                <InfoCircleOutlined style={{color: '#1D1C1C80', fontSize: '0.8rem', marginLeft: '5px'}} />
                              </Tooltip>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  ) : (
                    <Bar data={cardChartData} options={chartOptions} />
                  )}
                </Card>
              </Col>
            </Row>
          )}

          <section className={classNames.extendedSection} style={{margin: '1rem 0'}}>
            <Row gutter={14} style={{width: '100%', justifyContent: 'center'}}>
              {/* First Card */}
              <Col span={12} style={{paddingLeft: '0 !important'}}>
                <Card className={classNames.extendedCard}>
                  <div className={classNames.cardContent}>                   
                    <h3>Need Help?</h3>
                    <p>Contact your accountant for accounting and tax advice.</p>

                    <div className={classNames.flexRow} style={{
                      gap: '15px',
                      display: 'flex',
                      marginTop: '20px',
                    }}>
                      <CTAButton style={{
                        background: 'linear-gradient(92.72deg, #CF830E 8.37%, #C85103 94.73%)',
                        marginTop: '10px',
                        border: 'none'
                      }} onClick={() => {setIsModalVisible(true)}}>Book a Call</CTAButton>

                      <CTAButton style={{
                        marginTop: '10px',
                      }} onClick={() => {navigate('/app/chats', {
                        state: {
                          chatType: 'accountant'
                        }
                      })}}>Chat</CTAButton>
                    </div>
                  </div>
                </Card>
              </Col>
                  
              {/* Second Card */}
              <Col span={12} className={classNames.upgradePlan} style={{
                paddingRight: '0 !important', 
              }}>
                <Card className={classNames.extendedCard} ref={null}>
                  <div className={classNames.cardContent} >
                    <h3>Actions Pending {<InfoCircleFilled ref={flashIntervalRef} style={{color: 'white', marginLeft: '10px'}} />} </h3>
                    <p style={{ textAlign: 'left !important' }}>
                      {actionsPendingCount.bookkeeping + actionsPendingCount.tax === 0 && taxOnboarded === 'TAX_ONBOARDING_COMPLETE'
                        ? "You have no actions pending."
                        : actionsPendingCount.bookkeeping + actionsPendingCount.tax > 0 && taxOnboarded === 'TAX_ONBOARDING_COMPLETE'
                        ? `We need you to answer (${actionsPendingCount.bookkeeping + actionsPendingCount.tax}) questions.`
                        : actionsPendingCount.bookkeeping + actionsPendingCount.tax > 0 && taxOnboarded !== 'TAX_ONBOARDING_COMPLETE' && (new Date().getMonth() >= 10 || new Date().getMonth() <= 3)
                        ? `We need you to answer (${actionsPendingCount.bookkeeping + actionsPendingCount.tax}) questions and provide information for tax purposes.`
                        : taxOnboarded !== 'TAX_ONBOARDING_COMPLETE' && (new Date().getMonth() >= 10 || new Date().getMonth() <= 3)
                        ? "We need you to provide information for tax purposes."
                        : null}
                    </p>

                    <div className={classNames.flexRow} style={{
                      gap: '15px',
                      display: 'flex',
                      marginTop: '20px',
                    }}>
                      <CTAButton style={{
                          background: 'linear-gradient(92.72deg, #CF830E 8.37%, #C85103 94.73%)',
                          marginTop: '10px',
                          border: 'none',
                          width: '50%'
                        }} onClick={() => {navigate('/app/queries', {
                          state: {
                            type: 'Bookkeeping'
                          }
                        })}}>Respond</CTAButton>

                      {
                        taxOnboarded !== 'TAX_ONBOARDING_COMPLETE' && (new Date().getMonth() >= 10 || new Date().getMonth() <= 3) && (
                          <CTAButton style={{
                            marginTop: '10px',
                            width: '50%'
                          }} onClick={() => {navigate('/app/documents-vault')}}>Provide Documents</CTAButton>
                        )
                      }
                    </div>
                  </div>
                </Card>
              </Col>
            </Row>
          </section>
      </section>
      ) : (
        <section className={classNames.innerContent} style={{opacity: getBusinessStatus.isLoading || loading || accountLoading ? 0.3 : 1}}>
          {(getBusinessStatus.isLoading || loading || accountLoading) && ( // Render spinner if loading
            <div className={classNames.spinnerContainer} style={{zIndex: 10, position: 'absolute', left: 0}}>
              <Spin size="large" />
            </div>
          )}
          
          <h2 className={classNames.heading}>
          Get Started: {Object.keys(stepsCompleted).filter(key => stepsCompleted?.[key]).length} of {onboardingStepsOrder.length} Steps Completed
          </h2>

          <Steps progressDot current={currentStep} style={{ marginBottom: 20 }}>
            {stepsOrder.map((step, index) => (
              <Step key={step.key} title={step.title} />
            ))}
          </Steps>


          <section className={classNames.extendedSection}>
            <Row gutter={20} style={{ width: '100%' }}>
              {onboardingStepsOrder.map((step, index) => (
                <Col span={8} key={step.key}>
                  <Card className={classNames.extendedCard}>
                    <div className={classNames.cardContent}>
                      <h3 className={classNames.onboardingCard}>Step {index + 1}: {step.title}</h3>
                      <CTAButton
                        type="primary"
                        disabled={combinedStepsCompleted[step.key] || !isStepEnabled(index)} // Disable if completed or not enabled
                        style={{
                          backgroundColor: combinedStepsCompleted[step.key] ? 'green' : undefined, // Green background if completed
                          borderColor: combinedStepsCompleted[step.key] ? 'green' : undefined, // Green border if completed
                          color: combinedStepsCompleted[step.key] ? 'white' : undefined, // White text if completed
                        }}
                        onClick={() => {
                          // Open the corresponding modal based on the step
                          switch (step.key) {
                            case 'acceptTerms':
                              setIsTermsAndConditionModalVisible(true);
                              break;
                            case 'subscribePlan':
                              setIsSubscriptionModalVisible(true);
                              break;
                            case 'connectPlaidQuickbooks':
                              setIsAddAccountsModalVisible(true);
                              break;
                            default:
                              break;
                          }
                        }}
                      >
                        {getStepStatus(step)}
                      </CTAButton>
                    </div>
                  </Card>
                </Col>
              ))}
            </Row>
          </section>
        </section>
      )}

      <Modal
          title="Books & Taxes - Book a call with us"
          visible={isModalVisible}
          onCancel={() => {setIsModalVisible(false)}}  // This line should be sufficient to close the modal when clicking outside the modal or pressing the escape key.
          footer={null}
          closable={true}
          onCloseClick={() => {setIsModalVisible(false)}}
          width={800}
      >
          <div>
              <iframe
                  src="https://meetings.hubspot.com/jay-patel11"
                  width="100%"
                  height="600"
                  frameBorder="0"
                  title="HubSpotMeetingScheduler"
              ></iframe>
          </div>
      </Modal>

      <Modal
          title="Setup Payment Acceptance - Book a call with us"
          visible={isPaymentAcceptanceModalVisible}
          onCancel={()=>{setIsPaymentAcceptanceModalVisible(false)}}  // This line should be sufficient to close the modal when clicking outside the modal or pressing the escape key.
          footer={null}
          closable={true}
          onCloseClick={()=>{setIsPaymentAcceptanceModalVisible(false)}}
          width={800}
      >
        <Row justify="center" align="middle">
          <Col>
            <div style={{ textAlign: 'center' }}>
              <div style={{
                width: '500px',
                border: '1px solid rgb(255 255 255)',
                boxShadow: '0px 0px 8px 0px #c2c2c2',
                borderRadius: '3px'
              }}>
                <iframe
                  title='Schedule a meeting with Otterz'
                  src="https://meetings.hubspot.com/tapan-r?embed=true"
                  width="100%"
                  height="600px"
                  frameBorder="0"
                  style={{ border: 'none' }}
                  allowFullScreen
                ></iframe>
              </div>
              <Row style={{justifyContent: 'space-around', marginTop: '20px'}}>
                <CTAButton style={{width: '40%'}} onClick={() => {
                  Message({ type: "success", content: "Booking successful" });
                }}>Continue</CTAButton>
                <CTAButton style={{background: 'white', color: 'black', width: '40%'}} onClick={() => {setIsPaymentAcceptanceModalVisible(false)}}>Maybe Later</CTAButton>
              </Row>
            </div>
          </Col>
        </Row>
      </Modal>
      
      <AddAccountsModal isVisible={isAddAccountsModalVisible} onClose={setIsAddAccountsModalVisible} onSubmit={updatedStatus}/>
      <SubscriptionModal isVisible={isSubscriptionModalVisible} onClose={setIsSubscriptionModalVisible} onSubmit={updatedStatus}/>
      <TermsAndConditionModal isVisible={isTermsAndConditionModalVisible} onClose={setIsTermsAndConditionModalVisible} onSubmit={updatedStatus}/>
    </div>
  );
};

// Helper function to determine button label based on step key
const getButtonLabel = (stepKey) => {
  switch (stepKey) {
    case 'acceptTerms':
      return 'Accept Terms of Service';
    case 'subscribePlan':
      return 'Activate Subscription';
    case 'connectPlaidQuickbooks':
      return 'Connect Accounts';
    default:
      return 'Complete Step';
  }
};

export default LandingPage;
